import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import ContentEditable from 'react-contenteditable';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import { Calendar } from 'react-calendar';
import { Button } from 'reactstrap';
import InfiniteScroll from "react-infinite-scroll-component";
import { Player, ControlBar } from 'video-react';
import Footer from '../Footer';
import { checkboxChecked } from '../../utils/math';
import { MyContext } from "../../context"
import CustomPopUp from '../../CustomPopup';
export const DATE_TIME_LATEST_POST = "YYYY-MM-DDTHH:mm";
export const DATE_LATEST_POST = "YYYY-MM-DD";

class AddPost extends React.Component {
   static contextType = MyContext
   constructor(props) {
      super(props);
      this.contentEditable = React.createRef();
      this.state = {
         baseUrl: process.env.REACT_APP_BASE_URL, hourObj: {}, secondHourObj: {}, activecolor: "page", editerror2: "", colorIcon: "blur", posttext: "", posttext2: "",
         suggestionsList: [], tagtype: "", placetext: "Calendar your temporal information," + " " + localStorage.getItem('screenname').split(" ")[0] + "!", firstDate: "", secondDate: "", secondSelectedHour: "", secondTimedata2: "", timedata2: "", selectedMinutes: "", secondSelectedMinutes: "", selectedHour: "",
         checked2: "", imageVideoUrl: "", filewithpath: "", perpage: 1, searchPerameter: "", followers: [], items: Array.from({ length: 40 }), hasMore: true
         , selectedtype: 'everyone', selectedtypefinal: 'everyone', withwhodata: [], checkedItems: new Map(), checkedexcept: new Map(), checkedunexcept: new Map(),
         croseIcone: "", statepage: 0, searchPerameter: "", followerSearch: [], editwithwho: [], itemsearchfo: Array.from({ length: 40 }), itemsearch: Array.from({ length: 40 }), searchPage: 1,
         alphabetically: "", mutuality: "", editwhocanseemeta: [], editwhocansee: "", exp: false, unexceptdata: [],
         exceptdata: [], hashTagstext: "", isImportant: false, pathname: this.props.location.pathname, textEntryPopup: false, selectDatePopup: false, uploadError: false
      }
      this.getTime = this.getTime.bind(this);
      this.getmin = this.getmin.bind(this);

   }


   componentDidMount() {
      localStorage.setItem("footer", "add");
      $("#one_10 .more3").click(function () {
         $("#one_10 .pupup6").toggle();
         $("#one_10 .pupup6").addClass("zIndex");
      });
      $(".errorbtn").click(function () {
         $(".errorpop").toggle();
      });

      const { sharedData, setActiveTab } = this.context

      setActiveTab("third")

      if (sharedData) {
         if (sharedData.pageWiseData[this.state.pathname])
            this.setState({ ...sharedData.pageWiseData[this.state.pathname] }, () => {
               console.log({ posttext: this.state.posttext });
               if (this.state.posttext) {
                  this.hidePlaceholderText()
               }
            })

         this.setState({ pathname: this.props.location.pathname })
      }

   }

   componentWillUnmount() {
      this.updateSharedDataInContext()
   }
   updateSharedDataInContext() {
      const { sharedData, setSharedData } = this.context
      sharedData.pageWiseData[this.state.pathname] = this.state

      setSharedData({
         ...sharedData,
         authUser: this.state,
      });
   }
   gobackone() {
      $("#one_11").removeClass('active fades');
      $("#one_10").addClass('active fadesback');
   }
   gobacktwo() {
      $("#one_19").removeClass('active fades');
      $("#one_10").addClass('active fadesback');
   }
   gobackthree() {
      $("#one_20").removeClass('active fades');
      $("#one_10").addClass('active fadesback');
   }
   handleChangeunexcept(event) {
      var isChecked = event.target.checked;
      var item = event.target.value;
      if (isChecked == true) {
         $('#these' + item).addClass('active');
      }
      else {
         $('#these' + item).removeClass('active');
      }
      this.setState({ exp: true });
      this.setState(prevState => ({ checkedunexcept: prevState.checkedunexcept.set(item, isChecked) }));
   }

   setStorage() {
      localStorage.setItem("backid", "front");
   }
   handleChangeunexceptsearch(event) {
      var isChecked = event.target.checked;
      var item = event.target.value;
      if (isChecked == true) {
         $('#thesesearch' + item).addClass('act');
      }
      else {
         $('#thesesearch' + item).removeClass('act');
      }
      this.setState({ exp: true });
      this.setState(prevState => ({ checkedunexcept: prevState.checkedunexcept.set(item, isChecked) }));
   }

   unexcept() {

      this.setState({ selectedtype: 'particularfollowers', selectedtypefinal: 'only these followers...' }, () => {
         $("#one_20").removeClass("active fades");
         $("#one_10").addClass("active fadesback");
      });
      $("#one_10 .more3").addClass("protect");
      if (this.state.checkedunexcept) {
         var newarray = [];
         this.state.checkedunexcept.forEach(function (value, key) {
            if (value == true && key != 0) {
               newarray.push(key);
            }
         });
         this.setState({ unexceptdata: newarray });
      }
   }
   handleChangeexcept(event) {
      var isChecked = event.target.checked;
      var item = event.target.value;
      if (isChecked == true) {
         $('#elug' + item).addClass('active');
      }
      else {
         $('#elug' + item).removeClass('active');
      }
      this.setState({ exp: true });
      this.setState(prevState => ({ checkedexcept: prevState.checkedexcept.set(item, isChecked) }));
   }
   handleChangeexceptsearch(event) {
      var isChecked = event.target.checked;
      var item = event.target.value;
      if (isChecked == true) {
         $('#elugsearch' + item).addClass('act');
      }
      else {
         $('#elugsearch' + item).removeClass('act');
      }
      this.setState({ exp: true });
      this.setState(prevState => ({ checkedexcept: prevState.checkedexcept.set(item, isChecked) }));
   }
   except() {
      this.setState({ selectedtype: 'allfollowersexcept', selectedtypefinal: 'all followers except...' }, () => {
         $("#one_19").removeClass("active fades");
         $("#one_10").addClass("active fadesback");
      });
      $("#one_10 .more3").addClass("protect");
      if (this.state.checkedexcept) {
         var newarray = [];
         this.state.checkedexcept.forEach(function (value, key) {
            if (value == true && key != 0) {
               newarray.push(key);
            }
         });
         this.setState({ exceptdata: newarray });
      }
   }
   handleChangewithwho(event) {

      var isChecked = event.target.checked;
      var item = event.target.value;
      if (isChecked == true) {
         $('#slug' + item).addClass('active');
      }
      else {
         $('#slug' + item).removeClass('active');
      }
      this.setState({ changewithwho: true });
      this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
   }
   fetchMoreData() {
      setTimeout(() => {
         this.setState({ activecolor: 'first' });
         const variable = localStorage.getItem('userid');
         const token = localStorage.getItem('token');
         const totaluser = localStorage.getItem('followers');
         const recordp = totaluser / 40;
         if (this.state.perpage >= recordp) {
            this.setState({ hasMore: false });
            return;
         }
         this.setState({ perpage: this.state.perpage + 1 });
         fetch(this.state.baseUrl + '/api/follow/getFollowers.php?user_id=' + variable + '&page=' + this.state.perpage + '&limit=40', {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               for (var i = responseJson.response.length - 1; i >= 0; i--) {
                  this.state.followers.push(responseJson.response[i]);
               }
               if (this.state.mutuality != "") {
                  this.setState({ followers: this.state.followers.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse() });
               }
               if (this.state.alphabetically != "") {
                  this.setState({
                     followers: this.state.followers.sort((a, b) =>
                        a.screenName.localeCompare(b.screenName))
                  });
               }
               else {
                  this.setState({ followers: this.state.followers });
               }
            });
         this.setState({
            items: this.state.items.concat(Array.from({ length: 40 }))
            , loading: "false"
         });
      }, 1000);
   }

   handleChangewithwhoplug(event) {
      var isChecked = event.target.checked;
      var item = event.target.value;
      if (isChecked == true) {
         $('#plug' + item).addClass('act');
      }
      else {
         $('#plug' + item).removeClass('act');
      }
      this.setState({ changewithwho: true });
      this.setState(prevState => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
   }
   weekforNext() {
      if (this.state.followerSearch.length > 0) {
         return "loaderclass load";
      }
      else {
         return "loaderclass";
      }
   }
   onKeyUpValueFollower(event) {
      const variable = localStorage.getItem('userid');
      const token = localStorage.getItem('token');
      fetch(this.state.baseUrl + '/api/follow/followerSearch.php?user_id=' + variable + '&page=1&search=' + event.target.value + '&limit=40', {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            $('.userin').removeClass('active');
            this.setState({ followerSearch: [], followerSearch: responseJson.response });
         });
      this.setState({ searchPerameter: "", searchPerameter: event.target.value, statepage: 0, searchPage: 1 });
      this.setState({
         itemsearchfo: Array.from({ length: 40 })
      });
      if (event.target.value.length > 0) {
         this.setState({ croseIcone: event.target.value });
      }
      else {
         this.setState({ croseIcone: "" });
      }
   }
   fetchMoreDatafollowerSearch() {
      const variable = localStorage.getItem('userid');
      const token = localStorage.getItem('token');
      const totaluser = localStorage.getItem('followers');
      const pageno = 40;
      this.state.statepage = +40;
      this.setState({ statepage: this.state.statepage });
      if (this.state.followerSearch.length >= this.state.statepage) {
         this.state.searchPage = +1;
         this.setState({ searchPage: this.state.searchPage });
      }
      else {
         this.setState({ hasMore: false });
         return;
      }
      setTimeout(() => {
         fetch(this.state.baseUrl + '/api/follow/followerSearch.php?user_id=' + variable + '&page=' + this.state.searchPage + '&search=' + this.state.searchPerameter + '&limit=40', {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               for (var i = responseJson.response.length - 1; i >= 0; i--) {
                  this.state.followerSearch.push(responseJson.response[i]);
               }
               this.setState({
                  followerSearch: this.state.followerSearch
               });
            }, 3000);
      });

      this.setState({
         itemsearchfo: this.state.itemsearch.concat(Array.from({ length: 40 }))
      });
   }

   removefollowertext() {
      document.getElementById("lemon").value = "";
      this.setState({ followerSearch: [] });
      this.setState({ croseIcone: "" });
      this.gofollower();
   }
   setPostText(evt) {
      const caption = String(evt.target.value);

      this.setState({ posttext: caption });
      if (caption.length == 0) {
         this.showPlaceholderText();
      }
      else {
         this.hidePlaceholderText();
      }

   }

   posttext = evt => {

      var con1 = evt.target.value.replace(/<\/?span[^>]*>/g, "");
      var content = con1.replace(/<([^.]+)>.*?<\/\1>/ig, '');
      var content2 = content.replace('<br>', "");
      if (content2 == "" | content2 == "<br>") {
         $('#one_10 #labelplaceholder').removeClass('newlabel');
         $('#one_10 #labelholder').removeClass('newlabel');
         $('#one_10 #labelholderto').removeClass('newlabel');
         this.setState({ colorIcon: "blur" });
      }
      else {
         $('#one_10 #labelplaceholder').addClass('newlabel');
         $('#one_10 #labelholder').addClass('newlabel');
         $('#one_10 #labelholderto').addClass('newlabel');
         this.setState({ colorIcon: "" });
      }
      this.setState({ posttext: evt.target.value });
      var str = evt.target.value; // your string here
      var str1 = str.replace(/<\/?span[^>]*>/g, "");
      // var str2 = str1.replace(/<([^.]+)>.*?<\/\1>/ig, '');
      var str2 = str1.replace('<br>', " ");
      var str3 = str2.replace('<div> </div>', "<span></span>");
      var str4 = str3.replace(';', "");
      const regex = /(?:#)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)|(?:^|)(?:@)([A-Za-z0-9_-](?:(?:[A-Za-z0-9_-]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_-]))?)|(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\_~:/?#[\]@\$&'\(\)\*\+=]+/g;
      const replaced2 = str4.replace(regex, value => `<span class="dragon">${value}</span>`);
      this.setState({ posttext2: replaced2 });
      if (evt.target.value && evt.target.value.length > 0) {
         var n = evt.target.value.split(" ");
         var lastWord = n[n.length - 1];
         if (lastWord.includes("#")) {
            if (lastWord && !lastWord.includes("'")) {
               this.getHashTags(lastWord);
            }
         }
         else if (lastWord.includes("@")) {
            if (lastWord && !lastWord.includes("'")) {
               this.getMentions(lastWord);
            }
         }
         else {
            this.resetSuggestions();
         }
      }
      else {
         this.resetSuggestions();
      }
   }
   showPlaceholderText() {
      $('#one_10 #labelplaceholder').removeClass('newlabel');
      $('#one_10 #labelholder').removeClass('newlabel');
      $('#one_10 #labelholderto').removeClass('newlabel');
      this.setState({ colorIcon: "blur" });
   }

   hidePlaceholderText() {
      $('#one_10 #labelplaceholder').addClass('newlabel');
      $('#one_10 #labelholder').addClass('newlabel');
      $('#one_10 #labelholderto').addClass('newlabel');
      this.setState({ colorIcon: "" });
   }

   changeCurrentTime(seconds) {
      return () => {
         const { player } = this.player.getState();
         this.player.seek(player.currentTime + seconds);
      };
   }
   resetSuggestions() {
      var suggestionText = "";
      $('#one_10 .sugetionList').addClass('listb');
      this.setState({ suggestionsList: [], tagtype: "" });
   }
   getHashTags(lastWord) {
      const input = $('<span>').html(lastWord).text();
      var arr = input.split("#");
      if (arr.length > 1 && arr[arr.length - 1] != "") {
         var suggestionText = arr[arr.length - 1];
         const token = localStorage.getItem('token');
         fetch(this.state.baseUrl + '/api/event/getHashtag.php?hashtag=' + suggestionText, {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               if (responseJson.response) {
                  if (this.state.posttext && this.state.posttext.length > 0) {
                     this.setState({ suggestionsList: responseJson.response, tagtype: "hash" });

                     $('.sugetionList').removeClass('listb');
                  }
                  else {
                     this.resetSuggestions();
                  }
               } else {
                  this.resetSuggestions();
               }
            }).catch((error) => {
            })
      }
      else {
         this.setState({ suggestionsList: [] });
      }
   }
   getMentions(lastWord) {
      const input = $('<span>').html(lastWord).text();
      var arr = input.split("@");
      if (arr.length > 1 && arr[arr.length - 1] != "") {
         var suggestionText = arr[arr.length - 1];
         const token = localStorage.getItem('token');
         fetch(this.state.baseUrl + '/api/event/getMention.php?search=' + suggestionText, {
            method: "GET",
            headers: {
               Accept: "application/json",
               "Content-Type": "application/json",
               "Authorization": "Bearer " + token,
            },
         })
            .then((response) => response.json())
            .then((responseJson) => {
               if (responseJson.response) {
                  if (this.state.posttext && this.state.posttext.length > 0) {
                     this.setState({ suggestionsList: responseJson.response, tagtype: "mention" });
                     $('#one_10 .sugetionList').removeClass('listb');
                  }
                  else {
                     this.resetSuggestions();
                  }
               } else {
                  this.resetSuggestions();
               }

            }).catch((error) => {
            })
      }
      else {
         this.setState({ suggestionsList: [] });
      }
   }
   gettag(e) {
      var value = e.target.id;
      if (this.state.tagtype == "hash") {
         var my = this.state.posttext.split(" ").slice(0, -1).join(" ") + " " + "#" + value;
         const regex = /#(.+?)(?=[\s.,:,]|$)|@(.+?)(?=[\s.,:,]|$)/ig;
         const replaced2 = my.replace(regex, value => `<span>${value}</span>`);
         this.setState({ posttext2: replaced2 });
         this.setState({ posttext: my });
         var editable = $('#input');
         setTimeout(function () {
            document.getElementById("input").focus();
         }, 300);
         $('#one_10.sugetionList').addClass('listb');
      }
      else {
         var my = this.state.posttext.split(" ").slice(0, -1).join(" ") + " " + "@" + value;
         const regex = /#(.+?)(?=[\s.,:,]|$)|@(.+?)(?=[\s.,:,]|$)/ig;
         const replaced2 = my.replace(regex, value => `<span>${value}</span>`);
         this.setState({ posttext2: replaced2 });
         this.setState({ posttext: my });
         var editable = $('#input');
         setTimeout(function () {
            document.getElementById("input").focus();
         }, 300);
         $('#one_10 .sugetionList').addClass('listb');
      }
   }
   getTime(event) {
      var item = event.target.id;
      var items = event.target.className;
      if (this.state.timedata2 === item) {
         this.setState({
            selectedHour: '',
            timedata2: '',
            hourObj: {},
         });
         $('#' + item).removeClass('timecolor');
         return
      }
      if (this.state.timedata2 && this.state.secondTimedata2)
         $('#' + this.state.secondTimedata2).removeClass('timecolorsecond');

      if (this.state.secondTimedata2 === item) {
         this.setState({
            secondSelectedHour: '',
            secondTimedata2: '',
            secondHourObj: {},
         });
         $('#' + item).removeClass('timecolorsecond');
         return
      }

      if (item.slice(-2) == "pm" && items == 12) { // 12 PM issue
         var hourObj = { time: `${items}${item.slice(-2)}`, value: parseInt(items) };
      } else {
         var hourObj = { time: `${items}${item.slice(-2)}`, value: item.slice(-2) == "pm" ? parseInt(items) + 12 : parseInt(items) };
      }

      // let singleSelectedDate = (this.state.firstDate && !this.state.secondDate) || (!this.state.firstDate && this.state.secondDate)

      if (!this.state.selectedHour) {
         // if (singleSelectedDate && this.state.secondSelectedHour && this.state.secondSelectedHour < hourObj.value ) {

         //    $('#'+this.state.secondTimedata2).removeClass('timecolorsecond');
         //    $('#'+this.state.secondTimedata2).addClass('timecolor');
         //    $('#'+item).addClass('timecolorsecond');
         //    this.setState({
         //       selectedHour:this.state.secondSelectedHour,timedata2:this.state.secondTimedata2,hourObj:this.state.secondHourObj,
         //       secondSelectedHour:items,secondTimedata2:item,secondHourObj:hourObj,
         //    });

         // }
         // else {
         this.setState({ selectedHour: items, timedata2: item, hourObj: hourObj });
         $('#' + item).addClass('timecolor');
         // }    
      }
      else {
         // if (singleSelectedDate && hourObj.value < this.state.hourObj.value){
         //    $('#'+this.state.timedata2).removeClass('timecolor');
         //    $('#'+this.state.timedata2).addClass('timecolorsecond');
         //    $('#'+item).addClass('timecolor');
         //    this.setState({
         //       secondSelectedHour:this.state.selectedHour,secondTimedata2:this.state.timedata2,secondHourObj:this.state.hourObj,
         //       selectedHour:items,timedata2:item,hourObj:hourObj,
         //    });
         // }
         // else {
         this.setState({ secondSelectedHour: items, secondTimedata2: item, secondHourObj: hourObj });
         $('#' + item).addClass('timecolorsecond');
         // }               
      }
   }
   getmin(event) {
      const item = event.target.id;
      if (this.state.selectedMinutes === item) {
         $('#' + item).removeClass('timecolor');
         this.setState({ selectedMinutes: '' });
         return
      }
      if (this.state.secondSelectedMinutes === item) {
         $('#' + item).removeClass('timecolorsecond');
         this.setState({ secondSelectedMinutes: '' });
         return
      }
      if (this.state.selectedMinutes && this.state.secondSelectedMinutes)
         $('#' + this.state.secondSelectedMinutes).removeClass('timecolorsecond');

      // let singleSelectedDate = (this.state.firstDate && !this.state.secondDate) || (!this.state.firstDate && this.state.secondDate)

      if (!this.state.selectedMinutes) {
         // if (singleSelectedDate && this.state.secondSelectedMinutes && item > this.state.secondSelectedMinutes) {
         //    $('#' + item).addClass('timecolorsecond');
         //    $('#' + this.state.secondSelectedMinutes).removeClass('timecolorsecond')
         //    $('#' + this.state.secondSelectedMinutes).addClass('timecolor')
         //    this.setState({ selectedMinutes: this.state.secondSelectedMinutes, secondSelectedMinutes: item });
         // }
         // else  {
         $('#' + item).addClass('timecolor');
         this.setState({ selectedMinutes: item });
         // }
      }
      else {
         // if(singleSelectedDate && this.state.selectedMinutes && item < this.state.selectedMinutes) {
         //    $('#' + item).addClass('timecolor');
         //    $('#' + this.state.selectedMinutes).removeClass('timecolor')
         //    $('#' + this.state.selectedMinutes).addClass('timecolorsecond')
         //    this.setState({ selectedMinutes: item, secondSelectedMinutes: this.state.selectedMinutes });
         // }
         // else {
         $('#' + item).addClass('timecolorsecond');
         this.setState({ secondSelectedMinutes: item });
         // }
      }
   }
   setadd() {
      this.setState({ backid: "#one_10" });
   }
   onCalendarPostAdd = (e) => {
      var dateStr = moment(e).format('YYYY-MM-DD');
      if (this.state.firstDate === dateStr) {
         this.setState({ valueCalendar: '' });
         this.setState({ firstDate: '' });
         return
      }
      if (this.state.secondDate === dateStr) {
         this.setState({ valueCalendar: '', secondDate: '' });
         return
      }
      let selectedDate = new Date(dateStr)
      if (!this.state.firstDate) {
         if (this.state.secondDate && new Date(this.state.secondDate) < selectedDate)
            this.setState({ valueCalendar: e, firstDate: this.state.secondDate, secondDate: dateStr });
         else
            this.setState({ valueCalendar: e, firstDate: dateStr });
      }
      else if (this.state.firstDate) {
         let firstDateDate = new Date(this.state.firstDate)
         if (selectedDate > firstDateDate)
            this.setState({ secondDate: dateStr });
         else
            this.setState({ valueCalendar: "", secondDate: this.state.firstDate, firstDate: dateStr });
      }
   }

   handleChecktoggletwo() {
      if (this.state.checked2 != true) {
         this.setState({
            checked2: true
         })
      }
      else {
         this.setState({
            checked2: false
         })
      }
   }

   setIsImportant() {
      this.setState({
         isImportant: $(".newscreen01 #important").is(":checked"),
      });
      if ($(".newscreen01 #important").is(":checked"))
         $(".newscreen01 #important ~ .radiobtn").html(`<img src='/images/Checkmark.png' alt='active-img' />`)
      else
         $(".newscreen01 #important ~ .radiobtn").html(``)
   }

   findHashtags(searchText) {
      var regexp = /\B\#\w+\b/g;
      var result = searchText.match(regexp);
      if (result) {
         const filterTags = (result) => result.filter((v, i) => result.indexOf(v) === i);
         const hashTags = filterTags(result);
         if (hashTags) {
            const tags = hashTags.join().replace(/#/gi, "");
            this.setState({ hashTagstext: tags });
            return tags;
         }
      }
      return null;
   }
   onEveryone() {
      this.setState({ selectedtype: 'everyone', selectedtypefinal: 'everyone' });
      $("#one_10 .more3").removeClass("protect");
      $("#one_10 .pupup6").removeClass("zIndex");
   }
   onFollowers() {
      this.setState({ selectedtype: 'followers', selectedtypefinal: 'followers' });
      $("#one_10 .more3").removeClass("protect");
   }
   onFollowing() {
      this.setState({ selectedtype: 'following', selectedtypefinal: "users I'm following" });
      $("#one_10 .more3").addClass("protect");
   }
   onOnlyme() {
      this.setState({ selectedtype: 'onlyme', selectedtypefinal: 'only me' });
      $("#one_10 .more3").removeClass("protect");
      $("#one_10 .pupup6").removeClass("zIndex");
   }
   onCancel() {
      $("#one_10 .pupup6").removeClass("zIndex");
   }

   getPostedOnDates() {
      let {
         firstDate,
         hourObj,
         selectedMinutes,
         selectedHour,
         secondDate,
         secondHourObj,
         secondSelectedMinutes,
         secondSelectedHour,
      } = this.state;

      let fromDateObj = {
         dateStr: "",
         selectedHour: "",
         hourObj: {},
         selectedMinutes: "",
      };
      let toDateObj = { ...fromDateObj };

      fromDateObj.dateStr = firstDate || secondDate;
      fromDateObj.hourObj = hourObj;
      fromDateObj.selectedMinutes = selectedMinutes;
      fromDateObj.selectedHour = selectedHour;

      if (secondDate || secondHourObj.value || secondSelectedMinutes) {
         toDateObj.dateStr = secondDate || firstDate;
         toDateObj.selectedHour = secondSelectedHour;

         if (secondHourObj.value) {
            toDateObj.hourObj = secondHourObj;
         } else if (fromDateObj.dateStr == toDateObj.dateStr && secondSelectedMinutes) {
            toDateObj.hourObj = hourObj;
            toDateObj.selectedHour = selectedHour;
         }

         toDateObj.selectedMinutes = secondSelectedMinutes;
      }

      var postedOn = 0,
         postedOn2 = 0,
         newDate = "";

      if (fromDateObj.dateStr)
         var { momentString: postedOn, newDate: newDate } =
            this.getMomentAndDate(fromDateObj);

      if (toDateObj.dateStr)
         var { momentString: postedOn2 } = this.getMomentAndDate(toDateObj);

      return { postedOn, postedOn2, newDate };
   }

   getMomentAndDate({ dateStr, selectedHour, hourObj, selectedMinutes }) {

      const hour = Number(hourObj.value) < 10 ? `0${Number(hourObj.value)}` : Number(hourObj.value);
      var min = "00";
      if (selectedMinutes) {
         min = Number(selectedMinutes) < 10 ? `0${Number(selectedMinutes)}` : Number(selectedMinutes);
      }
      let postedOnString;
      if (selectedHour === "" && min === "00") {
         postedOnString = `${dateStr}`;
      } else {
         postedOnString = `${dateStr} ${hour || "00"}:${min}:00`;
      }
      let newDate;
      if (hour === "00" && min === "00") {
         newDate = moment(postedOnString).format(DATE_LATEST_POST);
      } else {
         newDate = moment(postedOnString).format(DATE_TIME_LATEST_POST);
      }
      var momentString = moment(newDate).unix();
      return { newDate, momentString };
   }

   submitPost() {
      if (this.state.posttext == "") {
         this.setState({ textEntryPopup: true });
         return;
      }
      if (this.state.firstDate == "" && this.state.secondDate == "") {
         this.setState({ selectDatePopup: true });
         return;
      }

      let postedOnDates = this.getPostedOnDates()
      let { postedOn, postedOn2, newDate } = postedOnDates

      if (postedOn2 && postedOn > postedOn2)
         [postedOn, postedOn2] = [postedOn2, postedOn]

      const created_at = Math.floor(new Date() / 1000);
      var formData = new FormData();
      formData.append("status", this.state.posttext);
      formData.append("include_in_feed", this.state.checked2 ? 0 : 1);
      formData.append("isImportant", this.state.isImportant ? 1 : 0);
      formData.append("postedOn", postedOn);
      formData.append("whocansee", this.state.selectedtype);
      formData.append("hashtags", this.findHashtags(this.state.posttext));
      formData.append("created_at", created_at);
      formData.append("timezone", moment.tz.guess());

      if (postedOn2)
         formData.append("postedOn2", postedOn2);

      if (this.state.withwhodata.length > 0) {
         this.state.withwhodata.map((data, i) => {
            formData.append("withwho[]", data);
         });
      }
      if (this.state.selectedtype == 'allfollowersexcept' || this.state.selectedtype == 'particularfollowers') {
         if (this.state.exceptdata.length > 0) {
            this.state.exceptdata.map((data, i) => {
               formData.append("whocanseemeta[]", data);
            });
         }
         if (this.state.unexceptdata.length > 0) {
            this.state.unexceptdata.map((data, i) => {
               formData.append("whocanseemeta[]", data);
            });
         }
      }
      if (this.state.postType == "image" || this.state.postType == "video") {
         formData.append("eventImage", this.state.filewithpath,
            this.state.filewithpath.name);
         formData.append("mediaType", this.state.postType);
      }
      if (this.state.postType == "image" || this.state.postType == "video") {
         formData.append("width", 800);
         formData.append("height", 1000);
      }
      for (var [key, value] of formData.entries()) { console.log('11111111111', key, value); }
      var body = formData;
      const token = localStorage.getItem('token');
      axios.post(this.state.baseUrl + '/api/event/add.php', body, {
         headers: {
            Accept: 'multipart/form-data',
            "Content-Type": 'multipart/form-data',
            "Authorization": "Bearer " + token,
         },
      })
         .then((res) => {
            if (res.data.message == "Post has been created successfully") {

               var e = new Date(newDate);
               localStorage.setItem('moveid', e)
               localStorage.setItem("backid", "front");
               this.props.history.push("/self");
               return;
            }
            else {
               this.setState({ editerror2: res.data.message });
               this.setState({ uploadError: true });
               return;
            }
         })
         .catch((error) => {
            this.setState({ editerror2: error.response.data.message });
            this.setState({ uploadError: true });
            return;
         })
   }
   onImageVideoChange = e => {
      let file = e.target.files[0];
      this.setState({ filewithpath: file });
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
         var streetaddress = reader.result.substr(0, reader.result.indexOf('/'));
         if (streetaddress == "data:image") {
            this.setState({ postType: "image" });
         }
         if (streetaddress == "data:video") {
            this.setState({ postType: "video" });
         }
         if (this.state.postType != "") {
            this.setState({
               imageVideoUrl: reader.result
            });
            $('.imagesscrol').addClass('srl');
            $("#one_10 .tab-content").animate({ scrollTop: $(document).height() }, 2000);
         }
      };
   }


   eleven() {
      $('#one_10').removeClass('active fadesback');
      $('#one_10').removeClass('active fades');
      $('#one_11').addClass('active fades');
      this.gofollower();
   }
   nineghty() {
      $('#one_10').removeClass('active fadesback');
      $('#one_10').removeClass('active fades');
      $('#one_19').addClass('active fades');
      this.gofollower();
   }
   twenty() {
      $('#one_10').removeClass('active fadesback');
      $('#one_10').removeClass('active fades');
      $('#one_20').addClass('active fades');
      this.gofollower();
   }
   gofollower() {
      const variable = localStorage.getItem('userid');
      const token = localStorage.getItem('token');
      this.setState({ activecolor: 'page', perpage: 1, searchPerameter: "", followers: [], items: 0, items: Array.from({ length: 40 }), hasMore: true });
      var pagepro = 1;
      fetch(this.state.baseUrl + '/api/follow/getFollowers.php?user_id=' + variable + '&page=' + pagepro + '&limit=40', {
         method: "GET",
         headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
         },
      })
         .then((response) => response.json())
         .then((responseJson) => {
            if (this.state.mutuality != "") {
               this.setState({ followers: responseJson.response.sort((a, b) => (a.mutual > b.mutual) ? 1 : -1).reverse() });
            }
            if (this.state.alphabetically != "") {
               this.setState({
                  followers: responseJson.response.sort((a, b) =>
                     a.screenName.localeCompare(b.screenName))
               });
            }
            else {
               this.setState({ followers: responseJson.response, loading: "false" });
            }

         });
   }
   withwho() {
      $("#one_11").removeClass("active fades");
      $("#one_10").addClass("active fadesback");
      if (this.state.checkedItems) {
         var newarray = [];
         this.state.checkedItems.forEach(function (value, key) {
            if (value == true && key != 0) {
               newarray.push(key);
            }
         });
         this.setState({ withwhodata: newarray });
      }
   }
   closeImage() {
      this.setState({ postType: "", imageVideoUrl: "" });
   }
   focuss() {
      document.getElementById("input").focus();
   }
   render() {
      return (
         //<div className="newscreen1 newscreen01">
         //<div className="screen1 active fades"  id="one_10">
         <>
            <div className={`${this.props.pos} screen1`} id="one_10">
               <div className="tab-content calendar_content pt-0">
                  <div className="add_to">
                     <h5>Calendar your temporal information</h5>
                     <div className="textaread textarea" onClick={this.focuss.bind(this)}>
                        <textarea
                           id="input"
                           className="textarea"
                           ref={this.contentEditable}
                           value={this.state.posttext}
                           onChange={this.setPostText.bind(this)}
                        />
                        <label id="labelplaceholder" >{this.state.placetext}</label>
                     </div>
                  </div>
                  <div className="sugetionList listb">
                     <ul className={this.state.suggestionsList.length > 0 ? "sugetionul" : "sugetionul closeCd"}>
                        {(() => {
                           if (this.state.suggestionsList.length > 0) {
                              return (
                                 this.state.suggestionsList.map((c, i) => {
                                    if (this.state.tagtype == "hash") {
                                       return (<li className="sugetionli" id={c.hashtag} onClick={this.gettag.bind(this)}>{'#' + c.hashtag}</li>)
                                    }
                                    else {
                                       return (<li className="sugetionli" id={c.username} onClick={this.gettag.bind(this)}>{"@" + c.username}</li>)
                                    }
                                 })
                              )
                           }
                        })()}
                     </ul>
                  </div>

                  <div className="sectionone calendar_test newcalendar">
                     <div className="inkap">
                        <div className="inkapil">
                           <Calendar
                              onChange={this.onCalendarPostAdd.bind(this)}
                              value={this.state.valueCalendar}
                              locale="en-US" // Set locale to US English
                              firstDayOfWeek={0}
                              tileClassName={({ date, view }) => {
                                 const dateStr = moment(date).format("YYYY-MM-DD");
                                 if (dateStr == this.state.firstDate)
                                    return "react-calendar__tile--active";
                                 if (dateStr == this.state.secondDate)
                                    return "pinkSecond";
                              }}
                           />
                        </div>
                     </div>
                     <div className="inkap">
                        <div className="inkapil hours-minutes__block">
                           <table className="table table-bordered text-center mb-0 hddak">
                              <thead>
                                 <tr>
                                    <th>Hour</th>
                                    <th>Minute</th>
                                 </tr>
                              </thead>
                           </table>
                           <table className="table table-bordered text-center mb-1">
                              <tbody>
                                 <tr>
                                    <td onClick={this.getTime} id="12pm" className="12">12pm</td>
                                    <td onClick={this.getTime} id="6pm" className="6">6pm</td>
                                    <td onClick={this.getTime} id="12am" className="0">12am</td>
                                    <td onClick={this.getTime} id="6am" className="6">6am</td>
                                    <td onClick={this.getmin} id="0" className="0">0</td>
                                    <td onClick={this.getmin} id="5" className="5">5</td>
                                 </tr>
                                 <tr>
                                    <td onClick={this.getTime} id="1pm" className="1">1pm</td>
                                    <td onClick={this.getTime} id="7pm" className="7">7pm</td>
                                    <td onClick={this.getTime} id="1am" className="1">1am</td>
                                    <td onClick={this.getTime} id="7am" className="7">7am</td>
                                    <td onClick={this.getmin} id="10" className="10">10</td>
                                    <td onClick={this.getmin} id="15" className="15">15</td>
                                 </tr>
                                 <tr>
                                    <td onClick={this.getTime} id="2pm" className="2">2pm</td>
                                    <td onClick={this.getTime} id="8pm" className="8">8pm</td>
                                    <td onClick={this.getTime} id="2am" className="2">2am</td>
                                    <td onClick={this.getTime} id="8am" className="8">8am</td>
                                    <td onClick={this.getmin} id="20" className="20">20</td>
                                    <td onClick={this.getmin} id="25" className="25">25</td>
                                 </tr>
                                 <tr>
                                    <td onClick={this.getTime} id="3pm" className="3">3pm</td>
                                    <td onClick={this.getTime} id="9pm" className="9">9pm</td>
                                    <td onClick={this.getTime} id="3am" className="3">3am</td>
                                    <td onClick={this.getTime} id="9am" className="9">9am</td>
                                    <td onClick={this.getmin} id="30" className="30">30</td>
                                    <td onClick={this.getmin} id="35" className="35">35</td>
                                 </tr>
                                 <tr>
                                    <td onClick={this.getTime} id="4pm" className="4">4pm</td>
                                    <td onClick={this.getTime} id="10pm" className="10">10pm</td>
                                    <td onClick={this.getTime} id="4am" className="4">4am</td>
                                    <td onClick={this.getTime} id="10am" className="10">10am</td>
                                    <td onClick={this.getmin} id="40" className="40">40</td>
                                    <td onClick={this.getmin} id="45" className="45">45</td>
                                 </tr>
                                 <tr>
                                    <td onClick={this.getTime} id="5pm" className="5">5pm</td>
                                    <td onClick={this.getTime} id="11pm" className="11">11pm</td>
                                    <td onClick={this.getTime} id="5am" className="5">5am</td>
                                    <td onClick={this.getTime} id="11am" className="11">11am</td>
                                    <td onClick={this.getmin} id="50" className="50">50</td>
                                    <td onClick={this.getmin} id="55" className="55">55</td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
                  <div className="optional optional2">
                     <div className='d-flex'>
                        <h3>Optional</h3>
                     </div>
                     <div className="row">
                        <div className="col-4 mb-2 pr-2">
                           <div className='flex-1'><p className='col-4'>Visibility</p> <span onclick={this.setadd.bind(this)} className="col-6 more3 box-border">{this.state.selectedtypefinal}<img src="../../images/down-facing_arrow.png" /></span></div>
                        </div>
                        <div className="col-4 mb-2 pr-2">
                           <label className="fille mb-0">
                              <span className='box-border'>add photo or video
                                 <input type="file" id="file" onChange={this.onImageVideoChange.bind(this)} name="myfile" />
                                 <img src="../../images/newspaper.png" alt="image" />
                              </span>
                           </label>
                        </div>
                        <div className="col-3 mb-2 text-right">
                           <label className="mb-0">
                              {/* <label className="checkcontainer"><input type="checkbox" onChange={this.handleChecktoggletwo.bind(this)} name="checkbox"/><span className="radiobtn"></span>only show on profile</label> */}
                              <label className="checkcontainer"><input type="checkbox" id="important" onChange={this.setIsImportant.bind(this)} name="checkbox" /><span className="radiobtn text-center"></span>important</label>
                           </label>
                        </div>
                        {/*   <div className="col-6 mb-2 pl-2">
               <a className="with_who link" onClick={this.eleven.bind(this)}> with who?</a>
            </div> */}

                     </div>
                     <h6>
                        <a className="btn post mb-2 box-border" onClick={this.submitPost.bind(this)}>Calendar</a>
                     </h6>
                     <div class={`col-12 mb-2 pl-2 imagesscrol ${this.state.imageVideoUrl ? '' : 'd-none'}`}>
                        {(() => {
                           if (this.state.postType == "image") {
                              return (<div className="imagesDiv"><div className="btncrose" onClick={this.closeImage.bind(this)}><img class="closeImgae" src="../../images/redcross.png" /></div><img id="blah2" class="w-100" src={this.state.imageVideoUrl} alt="your image" /></div>);
                           }
                           if (this.state.postType == "video") {
                              return (<div className="imagesDiv"><div class="video-div">

                                 <Player
                                    ref={player => {
                                       this.player = player;
                                    }}
                                    autoPlay={true}
                                 >
                                    <div className="btncrose" onClick={this.closeImage.bind(this)} ><img class="closeImgae" src="../../images/redcross.png" /></div>
                                    <source src={this.state.imageVideoUrl} type="video/mp4" />
                                    <ControlBar autoHide={false} className="my-class" />
                                 </Player>


                                 <ul class="videoulkap pb-3">
                                    <li><Button onClick={this.changeCurrentTime(-10).bind(this)} ><img class="img1" src="../../images/Backwards.png" />
                                       <span>10</span></Button>
                                    </li>
                                    <li><img class="img2" src="../../images/pause.png" /></li>
                                    <li><Button onClick={this.changeCurrentTime(10).bind(this)} ><img class="img3" src="../../images/Forwards.png" />
                                       <span>10</span></Button>
                                    </li>
                                 </ul>
                              </div> </div>)
                           }
                        })()}

                     </div>
                  </div>
               </div>
               <Footer {...this.props} />
               <div className="op pupup6">
                  <div className="more3 h-100"></div>
                  <ul>
                     <li><a className="more3" onClick={this.onEveryone.bind(this)} ><img src="../../images/everyone.png" /> everyone</a></li>
                     {/* <li><a className="more3" onClick={this.onFollowers.bind(this)} ><img src="../../images/arocicon.png"/> followers</a></li>
         <li><a  className="more3" onClick={this.onFollowing.bind(this)} ><img src="../../images/usercicon.png"/> users I'm following</a></li>
         <li><a className="link" onClick={this.nineghty.bind(this)} ><img src="../../images/seeLess.png"/> all followers except...</a></li>
         <li><a className="link" onClick={this.twenty.bind(this)} ><img src="../../images/tkicon.png"/> only these followers...</a></li> */}
                     <li><a className="more3" onClick={this.onOnlyme.bind(this)}><img src="../../images/onlyme.png" /> only me</a></li>
                     <button className="upbtn more3" onClick={this.onCancel.bind(this)}>Cancel</button>
                  </ul>
               </div>
               {this.state.textEntryPopup && (
                  <CustomPopUp
                     isOpen={this.state.textEntryPopup}
                     onClose={() => this.setState({ textEntryPopup: false })}
                     popup={{
                        heading: "Text entry",
                        message: `Enter some text, [${localStorage.getItem('screenname').split(" ")[0]}].`,
                        onClick: () => {
                           this.setState({ textEntryPopup: false });
                        },
                     }}
                     multipleButton={false}
                  />
               )}
               {this.state.selectDatePopup && (
                  <CustomPopUp
                     isOpen={this.state.selectDatePopup}
                     onClose={() => this.setState({ selectDatePopup: false })}
                     popup={{
                        heading: "Date selection",
                        message: `Select the date, ${localStorage.getItem('screenname').split(" ")[0]}.`,
                        onClick: () => {
                           this.setState({ selectDatePopup: false });
                        },
                     }}
                     multipleButton={false}
                  />
               )}
               {this.state.uploadError && (
                  <CustomPopUp
                     isOpen={this.state.uploadError}
                     onClose={() => this.setState({ uploadError: false })}
                     popup={{
                        heading: "Temporal information upload error",
                        message: `The temporal information failed to upload; please try again.`,
                        onClick: () => {
                           this.setState({ uploadError: false });
                        },
                     }}
                     multipleButton={false}
                  />
               )}
            </div>

            {/* -------------one_11------------ */}

            <div className="screen1" id="one_11">
               <div className="top" id="myHeader">
                  <section className="header headernoti2">
                     <div className="row">
                        <div className="col-4 header_left pr-0">
                           <ul className="nav nav-tabs">
                              <li><a className="linkback" data-toggle="tab" onClick={this.gobackone.bind(this)} ><img src="../../images/back.png" align="img" /></a></li>
                           </ul>
                        </div>
                        <div className="col-4 header_center pl-0 pr-0 text-center">
                           <h6>with who?</h6>
                        </div>
                        <div className="col-4 header_right pl-0">
                           <ul className="uld">
                              <li><a className="link " ><img onClick={this.withwho.bind(this)} src="../../images/tick.png" align="img" /></a></li>
                           </ul>
                        </div>
                     </div>
                  </section>
                  <div className="header_search">
                     <form className="form-inline mt-md-0">
                        <input className="form-control" id="lemon" type="text" onKeyUp={this.onKeyUpValueFollower.bind(this)} placeholder="search" aria-
                           label="Search" />
                        {(() => {
                           if (this.state.croseIcone.length > 0) {
                              return (<span className="btn"><img className="" onClick={this.removefollowertext.bind(this)} src="../../images/close(1).png" /></span>);
                           }
                           else {
                              return (<span className="btn"><img className="" src="../../images/search.png" /></span>);
                           }
                        })()}
                     </form>
                  </div>
               </div>
               <div className="followingmain withwho">
                  <div className="row py-0 my-0">
                     <div
                        id="scrollableDivto"
                        className="scrollClass newscreen1"
                        style={{
                           overflow: 'auto',
                           display: 'row',
                           flexDirection: 'row',
                           margintop: 47,
                        }}
                     >
                        {(() => {
                           if (this.state.followerSearch.length > 0) {
                              return (
                                 <InfiniteScroll
                                    dataLength={this.state.itemsearchfo.length}
                                    next={this.fetchMoreDatafollowerSearch.bind(this)}
                                    className="row"
                                    style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                    inverse={false} //
                                    hasMore={true}
                                    loader={
                                       <div className={this.weekforNext()}>
                                          <img
                                             src="../../images/newgif.gif"
                                             alt="loading"
                                          />
                                       </div>}
                                    useWindow={false}
                                    scrollableTarget="scrollableDivto"
                                 >

                                    {this.state.followerSearch.map((c, i) => {

                                       return (

                                          <div className="col-12">
                                             <div id={'plug' + c.user_id} className={this.state.editwithwho ? this.state.editwithwho.findIndex(x => x.user_id === c.user_id) !== -1 ? "userin act" : "userin" : "userin"}>
                                                <label className="checkcontainer">
                                                   <input type="checkbox" value={c.user_id} id={c.user_id} className="checkst"
                                                      onChange={this.handleChangewithwhoplug.bind(this)} name="checkbox" /><span className="radiobtn"></span>
                                                   <span className="imgg">
                                                      <img className="u_img" src={this.state.baseUrl + c.profileImage} align="images" />
                                                   </span>
                                                   <h6>{c.screenName}</h6>
                                                   <p>{c.mutual} mutual</p>
                                                </label>
                                             </div>
                                          </div>


                                       )

                                    }
                                    )
                                    }
                                 </InfiniteScroll>
                              )
                           }
                           else {

                              return (
                                 <InfiniteScroll
                                    dataLength={this.state.items.length}
                                    next={this.fetchMoreData.bind(this)}
                                    style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                    inverse={false} //
                                    className="row"
                                    hasMore={true}
                                    loader={
                                       <div className={this.weekforNext()}>
                                          <img
                                             src="../../images/newgif.gif"
                                             alt="loading"
                                          />
                                       </div>}
                                    useWindow={false}
                                    scrollableTarget="scrollableDivto"
                                 >
                                    {
                                       this.state.followers.map((p, i) => {
                                          return (
                                             <div className="col-12">
                                                <div id={'slug' + p.user_id} className={this.state.editwithwho ? this.state.editwithwho.findIndex(x => x.user_id === p.user_id) !== -1 ? "userin active" : "userin" : "userin"}>
                                                   <label className="checkcontainer">
                                                      <input type="checkbox" value={p.user_id}
                                                         onChange={this.handleChangewithwho.bind(this)} name="checkbox" className="checkst" /><span className="radiobtn"></span>
                                                      <span className="imgg">
                                                         <img className="u_img" src={this.state.baseUrl + p.profileImage} align="images" />
                                                      </span>
                                                      <h6>{p.screenName}</h6>
                                                      <p>{p.mutual} mutual</p>
                                                   </label>
                                                </div>
                                             </div>
                                          )
                                       }
                                       )
                                    }
                                 </InfiniteScroll>
                              );

                           }
                        })()}



                     </div>



                  </div>
               </div>
               <Footer {...this.props} />
            </div>
            {/* <!--************************ screen1 19 *************************--> */}
            <div className="screen1" id="one_19">
               <div className="top" id="myHeader">
                  <section className="header headernoti2">
                     <div className="row">
                        <div className="col-1 header_left pr-0">
                           <ul className="nav nav-tabs">
                              <li><a className="linkback" data-toggle="tab" onClick={this.gobacktwo.bind(this)}><img src="../../images/back.png" align="img" /></a></li>
                           </ul>
                        </div>
                        <div className="col-10 header_center pl-0 pr-0 text-center">
                           <h6>all followers except...</h6>
                        </div>
                        <div className="col-1 header_right pl-0">
                           <ul className="uld">
                              <li><a className="link more3" onClick={this.except.bind(this)} data-toggle="tab" ><img src="../../images/tick.png" align="img" /></a></li>
                           </ul>
                        </div>
                     </div>
                  </section>
                  <div className="header_search">
                     <form className="form-inline mt-md-0">
                        <input className="form-control" id="lemon" type="text" onKeyUp={this.onKeyUpValueFollower.bind(this)} placeholder="search" aria-
                           label="Search" />
                        {(() => {
                           if (this.state.croseIcone.length > 0) {
                              return (<span className="btn"><img className="" onClick={this.removefollowertext.bind(this)} src="../../images/close(1).png" /></span>);
                           }
                           else {
                              return (<span className="btn"><img className="" src="../../images/search.png" /></span>);
                           }
                        })()}
                     </form>
                  </div>
               </div>
               <div className="followingmain withwho">
                  <div className="row py-0 my-0">
                     <div
                        id="scrollableDivfourth"
                        className="scrollClass newscreen1"
                        style={{
                           overflow: 'auto',
                           display: 'row',
                           flexDirection: 'row',
                           margintop: 47,
                        }}
                     >
                        {(() => {
                           if (this.state.followerSearch.length > 0) {
                              return (
                                 <InfiniteScroll
                                    dataLength={this.state.itemsearchfo.length}
                                    next={this.fetchMoreDatafollowerSearch.bind(this)}
                                    className="row"
                                    style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                    inverse={false} //
                                    hasMore={true}
                                    loader={
                                       <div className="loaderclass">
                                          <img
                                             src="../../images/newgif.gif"
                                             alt="loading"
                                          />
                                       </div>}
                                    useWindow={false}
                                    scrollableTarget="scrollableDivfourth"
                                 >

                                    {this.state.followerSearch.map((c, i) => {
                                       return (
                                          <div className="col-12">
                                             <div className={this.state.editwhocanseemeta && this.state.editwhocansee == "allfollowersexcept" ? this.state.editwhocanseemeta.findIndex(x => x.user_id === c.user_id) !== -1 ? "userin act" : "userin" : "userin"} id={"elugsearch" + c.user_id}>
                                                <label className="checkcontainer">
                                                   <input type="checkbox" value={c.user_id} onChange={this.handleChangeexceptsearch.bind(this)} name="checkbox" /><span className="radiobtn"></span>
                                                   <span className="imgg">
                                                      <img className="u_img" src={this.state.baseUrl + c.profileImage} align="images" />
                                                   </span>
                                                   <h6>{c.screenName}</h6>
                                                   <p>{c.mutual} mutual</p>
                                                </label>
                                             </div>
                                          </div>


                                       )
                                    }
                                    )
                                    }
                                 </InfiniteScroll>
                              )
                           }
                           else {
                              return (
                                 <InfiniteScroll
                                    dataLength={this.state.items.length}
                                    next={this.fetchMoreData.bind(this)}
                                    style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                    inverse={false} //
                                    className="row"
                                    hasMore={true}
                                    loader={
                                       <div className={this.weekforNext()}>
                                          <img
                                             src="../../images/newgif.gif"
                                             alt="loading"
                                          />
                                       </div>}
                                    useWindow={false}
                                    scrollableTarget="scrollableDivfourth"
                                 >
                                    {
                                       this.state.followers.map((p, i) => {
                                          return (

                                             <div className="col-12">
                                                <div className={this.state.editwhocanseemeta && this.state.editwhocansee == "allfollowersexcept" ? this.state.editwhocanseemeta.findIndex(x => x.user_id === p.user_id) !== -1 ? "userin active" : "userin" : "userin"} id={"elug" + p.user_id}>
                                                   <label className="checkcontainer">
                                                      <input type="checkbox" value={p.user_id} onChange={this.handleChangeexcept.bind(this)} name="checkbox" /><span className="radiobtn"></span>
                                                      <span className="imgg">
                                                         <img className="u_img" src={this.state.baseUrl + p.profileImage} align="images" />
                                                      </span>
                                                      <h6>{p.screenName}</h6>
                                                      <p>{p.mutual} mutual</p>
                                                   </label>
                                                </div>
                                             </div>

                                          )
                                       }
                                       )
                                    }
                                 </InfiniteScroll>
                              );

                           }
                        })()}
                     </div>
                  </div>
               </div>
               <Footer {...this.props} />
            </div>




            {/* 
<!--************************ screen1 20 *************************--> */}
            <div className="screen1" id="one_20">
               <div className="top" id="myHeader">
                  <section className="header headernoti2">
                     <div className="row">
                        <div className="col-1 header_left pr-0">
                           <ul className="nav nav-tabs">
                              <li><a className="linkback" onClick={this.gobackthree.bind(this)} ><img src="../../images/back.png" align="img" /></a></li>
                           </ul>
                        </div>
                        <div className="col-10 header_center pl-0 pr-0 text-center">
                           <h6>only these followers...</h6>
                        </div>
                        <div className="col-1 header_right pl-0">
                           <ul className="uld">
                              <li><a className="link more3" onClick={this.unexcept.bind(this)} ><img src="../../images/tick.png" align="img" /></a></li>
                           </ul>
                        </div>
                     </div>
                  </section>
                  <div className="header_search">
                     <form className="form-inline mt-md-0">
                        <input className="form-control" id="lemon" type="text" onKeyUp={this.onKeyUpValueFollower.bind(this)} placeholder="search" aria-
                           label="Search" />
                        {(() => {
                           if (this.state.croseIcone.length > 0) {
                              return (<span className="btn"><img className="" onClick={this.removefollowertext.bind(this)} src="../../images/close(1).png" /></span>);
                           }
                           else {
                              return (<span className="btn"><img className="" src="../../images/search.png" /></span>);
                           }
                        })()}
                     </form>
                  </div>
               </div>
               <div className="followingmain withwho">
                  <div className="row py-0 my-0">
                     <div
                        id="scrollableDivfifth"
                        className="scrollClass newscreen1"
                        style={{
                           overflow: 'auto',
                           display: 'row',
                           flexDirection: 'row',
                           margintop: 47,
                        }}
                     >
                        {(() => {
                           if (this.state.followerSearch.length > 0) {
                              return (
                                 <InfiniteScroll
                                    dataLength={this.state.itemsearchfo.length}
                                    next={this.fetchMoreDatafollowerSearch.bind(this)}
                                    className="row"
                                    style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                    inverse={false} //
                                    hasMore={true}
                                    loader={
                                       <div className="loaderclass">
                                          <img
                                             src="../../images/newgif.gif"
                                             alt="loading"
                                          />
                                       </div>}
                                    useWindow={false}
                                    scrollableTarget="scrollableDivfifth"
                                 >
                                    {
                                       this.state.followers.map((p, i) => {
                                          return (
                                             <div className="col-12">
                                                <div className={this.state.editwhocanseemeta && this.state.editwhocansee == "particularfollowers" ? this.state.editwhocanseemeta.findIndex(x => x.user_id === p.user_id) !== -1 ? "userin act" : "userin" : "userin"} id={"thesesearch" + p.user_id}>
                                                   <label className="checkcontainer">
                                                      <input type="checkbox" value={p.user_id} onChange={this.handleChangeunexceptsearch.bind(this)} name="checkbox" /><span className="radiobtn"></span>
                                                      <span className="imgg">
                                                         <img className="u_img" src={this.state.baseUrl + p.profileImage} align="images" />
                                                      </span>
                                                      <h6>{p.screenName}</h6>
                                                      <p>{p.mutual} mutual</p>
                                                   </label>
                                                </div>
                                             </div>

                                          )
                                       }
                                       )

                                    }
                                 </InfiniteScroll>
                              )
                           }
                           else {

                              return (
                                 <InfiniteScroll
                                    dataLength={this.state.items.length}
                                    next={this.fetchMoreData.bind(this)}
                                    style={{ display: 'row', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                    inverse={false} //
                                    className="row"
                                    hasMore={true}
                                    loader={
                                       <div className={this.weekforNext()}>
                                          <img
                                             src="../../images/newgif.gif"
                                             alt="loading"
                                          />
                                       </div>}
                                    useWindow={false}
                                    scrollableTarget="scrollableDivfifth"
                                 >
                                    {
                                       this.state.followers.map((p, i) => {
                                          return (
                                             <div className="col-12">
                                                <div className={this.state.editwhocanseemeta && this.state.editwhocansee == "particularfollowers" ? this.state.editwhocanseemeta.findIndex(x => x.user_id === p.user_id) !== -1 ? "userin active" : "userin" : "userin"} id={"these" + p.user_id}>
                                                   <label className="checkcontainer">
                                                      <input type="checkbox" value={p.user_id} onChange={this.handleChangeunexcept.bind(this)} name="checkbox" /><span className="radiobtn"></span>
                                                      <span className="imgg">
                                                         <img className="u_img" src={this.state.baseUrl + p.profileImage} align="images" />
                                                      </span>
                                                      <h6>{p.screenName}</h6>
                                                      <p>{p.mutual} mutual</p>
                                                   </label>
                                                </div>
                                             </div>

                                          )
                                       }
                                       )

                                    }
                                 </InfiniteScroll>
                              );
                           }
                        })()}
                     </div>
                  </div>
               </div>
               <Footer {...this.props} />
            </div>
         </>
         //</div>
      )
   }
}
export default AddPost;