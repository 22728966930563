import $ from 'jquery';
import moment from 'moment';
import React from 'react';
import 'react-calendar/dist/Calendar.css';
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { Button } from 'reactstrap';
import { ControlBar, Player } from 'video-react';
import { fetchSavedTemporalInfo } from '../../Services/api';
import { MyContext } from '../../context';
import { getdateformat } from '../../utils/dateFormatterForTI/dateFormatter';
import { formatDate } from '../../utils/math';
import { fetchTeXCompiledCaption, modifyHref } from '../../utils/post/manageCaptions';
import { ReactComponent as TimelineSvg } from '../../utils/svg/Bidirectionally-traversed-timeline.svg';
import { ReactComponent as CalendarSvg } from '../../utils/svg/Calendar.svg';
import { ReactComponent as DepartSvg } from '../../utils/svg/Depart.svg';
import { formatUnixTimestamp } from '../../utils/time';
import { getActiveDatesOfTheMonth, getUniqueDatesOfTheMonth, getActiveDateBasedOnImportantEvent } from '../../utils/timetableHelper/timetableHelper';
import Footer from '../Footer';
import GuestFooter from '../GuestFooter';
import BookmarkTimetable from '../Timetable/BookmarkTimetable';

export const GRADIENT_COLORS = [
    { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#67d0ed" },
    { firstColor: "#67d0ed", secondColor: "#67d0ed", textColor: "#ffffff" },
    { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#333333" },
    { firstColor: "#cccccc", secondColor: "#cccccc", textColor: "#67d0ed" },
    { firstColor: "#b9026c", secondColor: "#fd222e", textColor: "#ffffff" },
    { firstColor: "#f0f0f0", secondColor: "#f0f0f0", textColor: "#b9026c" }];

class SavedTemporalInfo extends React.Component {
    static contextType = MyContext
    constructor(props) {
        super(props);
        let UserName = null;
        const params = this.props.location.pathname.split('/');
        UserName = params[params.length - 1];
        this.state = {
            Username: UserName,
            randColor: {},
            cscreenname: "",
            cbio: "",
            cwebsite: "",
            eventsList: [],
            other_minimum: "",
            other_maximum: "",
            evntData: [],
            contat: "of",
            topcontat: "of",
            baseUrl: process.env.REACT_APP_BASE_URL,
            baseUrlNode: process.env.REACT_APP_BASE_URL_NODE,
            time: false,
            monthYearList: [],
            monthYearWithDatesList: {},
            umid: "",
            hasMore: true,
            pathname: this.props.location.pathname,
            clickedButtonToExit: "",
            activeTab: "",
            isTimelineActive: false,
            leftSideArray: [],
            monthYearDateMap: {},
            preventNavigation: false,
            shouldUpdateOnSamePath: false,
            timetableData: {
                threeMonthsEarlier: {
                    activeDate: undefined,
                    prevDate: undefined,
                    nextDate: undefined,
                    minDate: undefined,
                    maxDate: undefined,
                    profileData: [],
                    availableEventsOnActiveDate: [],
                    availableEventsOnDates: [],
                    appendedTiles: { data: [], appendedMonth: null },
                    savePost: []
                },
                twoMonthsEarlier: {
                    activeDate: undefined,
                    prevDate: undefined,
                    nextDate: undefined,
                    minDate: undefined,
                    maxDate: undefined,
                    profileData: [],
                    availableEventsOnActiveDate: [],
                    availableEventsOnDates: [],
                    appendedTiles: { data: [], appendedMonth: null },
                    savePost: []
                },
                previous: {
                    activeDate: undefined,
                    prevDate: undefined,
                    nextDate: undefined,
                    minDate: undefined,
                    maxDate: undefined,
                    profileData: [],
                    availableEventsOnActiveDate: [],
                    availableEventsOnDates: [],
                    appendedTiles: { data: [], appendedMonth: null },
                    savePost: []
                },
                current: {
                    activeDate: undefined,
                    prevDate: undefined,
                    nextDate: undefined,
                    minDate: undefined,
                    maxDate: undefined,
                    profileData: [],
                    availableEventsOnActiveDate: [],
                    availableEventsOnDates: [],
                    appendedTiles: { data: [], appendedMonth: null },
                    savePost: []
                },
                next: {
                    activeDate: undefined,
                    prevDate: undefined,
                    nextDate: undefined,
                    minDate: undefined,
                    maxDate: undefined,
                    profileData: [],
                    availableEventsOnActiveDate: [],
                    availableEventsOnDates: [],
                    appendedTiles: { data: [], appendedMonth: null },
                    savePost: []
                },
                twoMonthsAhead: {
                    activeDate: undefined,
                    prevDate: undefined,
                    nextDate: undefined,
                    minDate: undefined,
                    maxDate: undefined,
                    profileData: [],
                    availableEventsOnActiveDate: [],
                    availableEventsOnDates: [],
                    appendedTiles: { data: [], appendedMonth: null },
                    savePost: []
                },
                threeMonthsAhead: {
                    activeDate: undefined,
                    prevDate: undefined,
                    nextDate: undefined,
                    minDate: undefined,
                    maxDate: undefined,
                    profileData: [],
                    availableEventsOnActiveDate: [],
                    availableEventsOnDates: [],
                    appendedTiles: { data: [], appendedMonth: null },
                    savePost: []
                }
            },
        };
        this.child = React.createRef();
        this.calendarRef = React.createRef();
        this.footerPropsForUpdateOnSamePath = {
            isParentUserProfile: true,
            setShouldUpdateOnSamePath: this.setShouldUpdateOnSamePath
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const { appContext } = this.props;
        const { state } = appContext;
        // check when the homepage status will update
        if (prevProps.appContext.state !== state) {
            this.checkBackNavigation();
        }

        if (this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1] !== "" && this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1] !== undefined) {
            if (this.state.Username !== this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1]) {
                var random_color = GRADIENT_COLORS[Math.floor(Math.random() * GRADIENT_COLORS.length)];
                this.setState({ randColor: random_color, Username: this.props.location.pathname.split('/')[this.props.location.pathname.split('/').length - 1] });
            }
        }
        const { activeTab, sharedData } = this.context
        if (this.state.shouldUpdateOnSamePath) {
            if (activeTab !== prevState.activeTab)
                this.setState({ activeTab })

            this.props.setVisitedPaths([...sharedData.tabWiseData[activeTab].openProfiles])
            this.checkBackNavigation();
            this.setShouldUpdateOnSamePath(false)
        }
    }
    handleMouseOver() {
        $(".katexContentBlock .katex a").attr("target", "_blank");
        $(".katexContentBlock .katex a").attr("rel", "noopener noreferrer");
    }
    componentDidMount() {
        const { sharedData, activeTab } = this.context;

        if (sharedData) {
            if (sharedData.pageWiseData['saved'])
                this.setState({ ...sharedData.pageWiseData['saved'] })
        }

        this.getSavedTemporalInfo({freshLoad: true});

        this.setState({ activeTab: activeTab, preventNavigation: false });
        localStorage.setItem("footer", "second");
        document.addEventListener('mouseover', this.handleMouseOver);
    }

    updateSharedDataInContext() {
        const { sharedData, setSharedData } = this.context

        sharedData.pageWiseData[this.state.pathname] = this.state

        setSharedData({
            ...sharedData,
            lastViewedUser: this.state,
        });
    }

    async getSavedTemporalInfo(obj) {
        const token = localStorage.getItem('token');
        if (!token) {
            const savedInfo = JSON.parse(localStorage.getItem('savedTemporalInfo'));
            if (!savedInfo?.length && obj?.freshLoad) {
                this.setState({ time: true });
                return
            }
            const { sortedEvents, monthYearArray, monthYearWithDatesObject } = this.createEventList(savedInfo);
            this.setState({ eventsList: sortedEvents, monthYearList: monthYearArray, monthYearWithDatesList: monthYearWithDatesObject }, () => {
                this.mapMonthYearToDate().then(() => this.setActiveDatesOfTimetables());
            });
        } else {
            const savedInfo = await fetchSavedTemporalInfo();
            if (!savedInfo?.length) {
                this.setState({ time: true });
                return
            }
            const { sortedEvents, monthYearArray, monthYearWithDatesObject } = this.createEventList(savedInfo);
            this.setState({ eventsList: sortedEvents, monthYearList: monthYearArray, monthYearWithDatesList: monthYearWithDatesObject }, () => {
                this.mapMonthYearToDate().then(() => this.setActiveDatesOfTimetables());
            });
        }
    }

    createEventList(savedPosts) {
        const eventMap = {};
        const currentDate = moment();

        if (savedPosts?.length > 0) {
            savedPosts.forEach(post => {
                const dates = post.postedOn2 ? [post.postedOn, post.postedOn2] : [post.postedOn];

                dates.forEach(date => {
                    const eventOn = formatUnixTimestamp(date, post.timezone)[0].format("DD-MM-YYYY");
                    const userKey = post.screenName || post.eventBy;
                    const mapKey = `${eventOn}_${userKey}`;

                    const timeDiff = currentDate.diff(moment.unix(date), 'seconds');

                    if (!eventMap[mapKey]) {
                        eventMap[mapKey] = {
                            eventOn,
                            eventBy: post.eventBy,
                            dateCount: 0,
                            TIMEDIFF: timeDiff,
                            priority: 1,
                            importantEvents: 0
                        };
                    }

                    eventMap[mapKey].dateCount += 1;

                    if (post.isImportant) {
                        eventMap[mapKey].importantEvents += 1;
                    }
                });
            });
            const eventList = Object.values(eventMap).sort((a, b) => (moment(a.eventOn, "DDMMYYYY") > moment(b.eventOn, "DDMMYYYY")) ? 1 : -1).reverse();
            const monthYearArray = [];
            const monthYearWithDatesObject = {};

            const sortedEvents = eventList
                .sort((a, b) => {
                    const dateA = moment(a.eventOn, "DD-MM-YYYY");
                    const dateB = moment(b.eventOn, "DD-MM-YYYY");

                    if (dateB.year() !== dateA.year()) {
                        return dateB.year() - dateA.year();
                    }

                    return dateB.month() - dateA.month();
                })
                .map(event => {
                    const date = moment(event.eventOn, "DD-MM-YYYY");
                    const monthYear = date.format("MMYYYY");

                    if (!monthYearArray.includes(monthYear)) {
                        monthYearArray.push(monthYear);
                        monthYearWithDatesObject[monthYear] = date.toDate();
                    }

                    return event;
                });

            this.setState({
                other_minimum: moment(sortedEvents[sortedEvents.length - 1].eventOn, "DD-MM-YYYY").toDate(),
                other_maximum: moment(sortedEvents[0].eventOn, "DD-MM-YYYY").toDate()
            });

            return { sortedEvents, monthYearArray, monthYearWithDatesObject };
        } else {
            return { sortedEvents: [], monthYearArray: [], monthYearWithDatesObject: [] };
        }
    }

    setActiveDatesOfTimetables(date) {
        const eventList = this.state.eventsList;
        const monthYears = this.state.monthYearList;
        let nearestEventDate = null;
        let nearestEventMonthYear = null;

        if (!eventList.length)
            return

        if (date) {
            nearestEventDate = date;
            nearestEventMonthYear = moment(date).format("MMYYYY");
        } else {
            const nearestEvent = eventList.reduce((closest, event) => {
                return Math.abs(event.TIMEDIFF) < Math.abs(closest.TIMEDIFF) ? event : closest;
            });
            nearestEventDate = moment(nearestEvent.eventOn, "DD-MM-YYYY").toDate();
            nearestEventMonthYear = moment(nearestEventDate).format("MMYYYY");
        }
        const monthDateMapping = this.setShowMonths(nearestEventDate); // to set the months to show on header
        const { next, twoMonthsAhead, threeMonthsAhead, prev, twoMonthsEarlier, threeMonthsEarlier } = this.getActiveDatesOfNextPrevMonths(date, monthDateMapping);
        
        this.setState(prevState => ({
            timetableData: {
                threeMonthsEarlier: {
                    ...prevState.timetableData.threeMonthsEarlier,
                    activeDate: threeMonthsEarlier,
                    availableEventsOnActiveDate: this.getEventsOfActiveDate(threeMonthsEarlier),
                    availableEventsOnDates: getUniqueDatesOfTheMonth(getActiveDatesOfTheMonth(threeMonthsEarlier, this.state.eventsList)),
                },
                twoMonthsEarlier: {
                    ...prevState.timetableData.twoMonthsEarlier,
                    activeDate: twoMonthsEarlier,
                    availableEventsOnActiveDate: this.getEventsOfActiveDate(twoMonthsEarlier),
                    availableEventsOnDates: getUniqueDatesOfTheMonth(getActiveDatesOfTheMonth(twoMonthsEarlier, this.state.eventsList)),
                },
                previous: {
                    ...prevState.timetableData.previous,
                    activeDate: prev,
                    availableEventsOnActiveDate: this.getEventsOfActiveDate(prev),
                    availableEventsOnDates: getUniqueDatesOfTheMonth(getActiveDatesOfTheMonth(prev, this.state.eventsList)),
                },
                current: {
                    ...prevState.timetableData.current,
                    activeDate: nearestEventDate,
                    availableEventsOnActiveDate: this.getEventsOfActiveDate(nearestEventDate),
                    availableEventsOnDates: getUniqueDatesOfTheMonth(getActiveDatesOfTheMonth(nearestEventDate, this.state.eventsList)),
                },
                next: {
                    ...prevState.timetableData.next,
                    activeDate: next,
                    availableEventsOnActiveDate: this.getEventsOfActiveDate(next),
                    availableEventsOnDates: getUniqueDatesOfTheMonth(getActiveDatesOfTheMonth(next, this.state.eventsList)),
                },
                twoMonthsAhead: {
                    ...prevState.timetableData.twoMonthsAhead,
                    activeDate: twoMonthsAhead,
                    availableEventsOnActiveDate: this.getEventsOfActiveDate(twoMonthsAhead),
                    availableEventsOnDates: getUniqueDatesOfTheMonth(getActiveDatesOfTheMonth(twoMonthsAhead, this.state.eventsList)),
                },
                threeMonthsAhead: {
                    ...prevState.timetableData.threeMonthsAhead,
                    activeDate: threeMonthsAhead,
                    availableEventsOnActiveDate: this.getEventsOfActiveDate(threeMonthsAhead),
                    availableEventsOnDates: getUniqueDatesOfTheMonth(getActiveDatesOfTheMonth(threeMonthsAhead, this.state.eventsList)),
                }
            }
        }), () => {
            this.setState({ time: true });
        })
    }

    setShowMonths(dateActive) {
        const monthsShort = {
            '01': "Jan",
            '02': 'Feb',
            '03': 'Mar',
            '04': 'Apr',
            '05': 'May',
            '06': 'Jun',
            '07': 'Jul',
            '08': 'Aug',
            '09': 'Sep',
            '10': 'Oct',
            '11': 'Nov',
            '12': 'Dec'
        };
        const dateIndex = moment(dateActive).format("MMYYYY")
        const sortedArray = this.getSurroundingMonths(dateIndex);
        const result = sortedArray.map(({ month, year, originalIndex }, index) => {
            return {
                date: `${month < 10 ? '0' : ''}${month}${year}`,
                originalIndex,
                display: index < 5
            }
        })
            .sort((a, b) => a.originalIndex - b.originalIndex);
        const activeIndex = result.indexOf(dateIndex);
        const mappedArray = result.map(dateObj => {
            let { date, display } = dateObj;
            const month = date.substr(0, 2);
            const year = date.substr(4, 2);
            const monthName = monthsShort[month];
            const currentDate = new Date();
            const currentYear = moment(currentDate).format('YYYY');
            const MY = `${monthName}`

            return { short: MY, date: this.state.monthYearDateMap[date]?.maxDate, isActiveMonth: date === dateIndex, display }
        });
    
        this.setState({ leftSideArray: mappedArray.filter(map => map.display) });
        return mappedArray;
    }

    getClosestValues = (activeIndex, array) => {
        const parseMonthYear = (value) => {
            const year = parseInt(value.slice(2, 6), 10);
            const month = parseInt(value.slice(0, 2), 10);
            return { year, month };
        };
        const activeDate = parseMonthYear(activeIndex);
        const parsedArray = array.map((value, index) => ({ ...parseMonthYear(value), originalIndex: index }));
        const sortedArray = parsedArray.sort((a, b) => {
            const diffA = Math.abs((a.year - activeDate.year) * 12 + (a.month - activeDate.month));
            const diffB = Math.abs((b.year - activeDate.year) * 12 + (b.month - activeDate.month));
            return diffA - diffB;
        });
        return sortedArray;
    };

    getSurroundingMonths = (activeMonth) => {
        let months = [...this.state.monthYearList];
        months.reverse();
        const activeIndex = months[months.indexOf(activeMonth)];
        const surroundingMonths = this.getClosestValues(activeIndex, months);
        return surroundingMonths;
    }

    mapMonthYearToDate = async () => {
        let lastMY = ""
        let lastMYDate = ""
        let monthYearDateMap = {}
        this.state.eventsList.forEach((dateObj) => {
            var min = moment(dateObj.eventOn, "DD/MM/YYYY");
            var minie = new Date(min);
            const currentMY = moment(minie).format("MMYYYY")
            if (currentMY !== lastMY) {
                monthYearDateMap[lastMY] = { ...monthYearDateMap[lastMY], minDate: lastMYDate }
                monthYearDateMap[currentMY] = { ...monthYearDateMap[lastMY], maxDate: minie }
            }
            lastMY = currentMY
            lastMYDate = minie
        })
        monthYearDateMap[lastMY] = { ...monthYearDateMap[lastMY], minDate: lastMYDate }
        this.setState({ monthYearDateMap: { ...monthYearDateMap } }, () => {
            this.updateMinMaxDateOfTimetables();
        })
    }

    updateMinMaxDateOfTimetables() {
        const otherMonthYear = this.state.monthYearList;
        const monthYearDateMap = this.state.monthYearDateMap;
        this.setState(prevState => ({
            timetableData: {
                threeMonthsEarlier: {
                    ...prevState.timetableData.threeMonthsEarlier,
                    minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 1]]?.minDate || undefined,
                    maxDate: monthYearDateMap[otherMonthYear[3]]?.maxDate || undefined,
                },
                twoMonthsEarlier: {
                    ...prevState.timetableData.twoMonthsEarlier,
                    minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 1]]?.minDate || undefined,
                    maxDate: monthYearDateMap[otherMonthYear[2]]?.maxDate || undefined,
                },
                previous: {
                    ...prevState.timetableData.previous,
                    minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 1]]?.minDate || undefined,
                    maxDate: monthYearDateMap[otherMonthYear[1]]?.maxDate || undefined,
                },
                current: {
                    ...prevState.timetableData.current,
                    minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 1]]?.minDate || undefined,
                    maxDate: monthYearDateMap[otherMonthYear[0]]?.maxDate || undefined,
                },
                next: {
                    ...prevState.timetableData.next,
                    minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 2]]?.minDate || undefined,
                    maxDate: monthYearDateMap[otherMonthYear[0]]?.maxDate || undefined,
                },
                twoMonthsAhead: {
                    ...prevState.timetableData.twoMonthsAhead,
                    minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 3]]?.minDate || undefined,
                    maxDate: monthYearDateMap[otherMonthYear[0]]?.maxDate || undefined,
                },
                threeMonthsAhead: {
                    ...prevState.timetableData.threeMonthsAhead,
                    minDate: monthYearDateMap[otherMonthYear[otherMonthYear.length - 4]]?.minDate || undefined,
                    maxDate: monthYearDateMap[otherMonthYear[0]]?.maxDate || undefined,
                }
            }
        }));
    }


    /**
     * 
     * @param {string} buttonName 
     */
    updateClickedButtonToExit(buttonName) {
        this.setState({ clickedButtonToExit: buttonName })
    }

    checkBackNavigation() {
        const { activeTab } = this.context
        if (activeTab === 'second') {
            const { sharedData } = this.context;
            let activeTabData = sharedData.tabWiseData[activeTab]?.openProfiles;
            const { appContext } = this.props;
            const { state } = appContext;
            if (activeTabData?.length === 1 && state.homePageActive.status) {
                this.setState({ preventNavigation: true });
            } else {
                this.setState({ preventNavigation: false });
            }
        } else {
            this.setState({ preventNavigation: false });
        }
    }

    mainProfileColor() {
        $(".username, .usernamecl").css("color", "");
        $(".username, .usernamecl").css("color", this.state.randColor.textColor);
        $(".username, .usernamecl").css("background-color", "");
        $(".username, .usernamecl").css("background-image", `linear-gradient(${this.state.randColor.firstColor}, ${this.state.randColor.secondColor})`);
    }

    fetchEvntList() {
        if (this.state.evntData.length >= 20)
            return

        if (this.state.hasMore == true) {
            const variable = localStorage.getItem('userid');
            const token = localStorage.getItem('token');
            this.setState({ hasMore: true })
            setTimeout(() => {

                var date = Date.now() / 1000;
                var serverpath = this.state.baseUrl + '/api/event/getEventsUserSpecific.php?userID=' + this.state.umid + '&date=' + date;

                fetch(serverpath, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "TimeZone": moment.tz.guess(),
                        "Authorization": "Bearer " + token,
                    },
                })
                    .then((response) => response.json())
                    .then((responseJson) => {
                        const events = responseJson.response;
                        if (events.length < 20)
                            this.setState({ contat: 'on', topcontat: 'on', hasMore: false })

                        if (responseJson.message == "Event not available") {
                            $('#one_14 .loaderclass').removeClass('downscroll');
                            $('#one_14 .loaderclass').addClass('stopscroll');
                        }
                        if (events.length) {
                            this.setState({ evntData: events }, () => {
                                const centerEventIndex = Math.floor(events.length / 2)

                                const centerEventHashDivSelector = `#hashu${events[centerEventIndex].eventID}`
                                this.scrollTimelineToElementPosition(centerEventHashDivSelector);
                            })

                        }
                    });
            }, 100);
        }
    }

    /**
     * will scroll the Infinite Scroll to *offsetTop* position of the element selected by given jquery selector 
     * 
     * @param {string} selector HTML Element selector 
     */
    scrollTimelineToElementPosition(selector) {
        $('#one_14 #scrollableDivOneFourProfile').scrollTop($(`#one_14 ${selector}`)[0]?.offsetTop - 10);
    }

    updateTimetableData = (data, calendarView) => {
        if (data && ['current', 'next', 'previous', 'twoMonthsEarlier', 'twoMonthsAhead', 'threeMonthsEarlier', 'threeMonthsAhead'].includes(calendarView)) {
            this.setState((prevState) => ({
                timetableData: {
                    ...prevState.timetableData,
                    [calendarView]: {
                        ...prevState.timetableData[calendarView],
                        ...data,
                    },
                },
            }));
        }
    };

    convertToDate = (activeDate) => {
        return activeDate?.eventOn ? moment(activeDate.eventOn, "DD-MM-YYYY").toDate() : undefined;
    }

    getActiveDatesOfNextPrevMonths(date, monthDateMapping) {
        const activeDates = {
            threeMonthsAhead: undefined,
            twoMonthsAhead: undefined,
            next: undefined,
            prev: undefined,
            twoMonthsEarlier: undefined,
            threeMonthsEarlier: undefined
        };

        const currentMonthIndex = monthDateMapping.findIndex(el => el.isActiveMonth);

        const processDateAtIndex = (index) => {
            const date = monthDateMapping[currentMonthIndex + index]?.date;
            const availableEvents = getActiveDatesOfTheMonth(date, this.state.eventsList);
            return this.convertToDate(getActiveDateBasedOnImportantEvent(date, availableEvents));
        };

        activeDates.threeMonthsAhead = processDateAtIndex(3);
        activeDates.twoMonthsAhead = processDateAtIndex(2);
        activeDates.next = processDateAtIndex(1);

        let pastMonthsNeeded = 0;
        if (!activeDates.threeMonthsAhead) pastMonthsNeeded = 1;
        if (!activeDates.twoMonthsAhead) pastMonthsNeeded = 2;
        if (!activeDates.next) pastMonthsNeeded = 3;

        if (pastMonthsNeeded > 0) {
            activeDates.prev = processDateAtIndex(-1);
            if (pastMonthsNeeded > 1) {
                activeDates.twoMonthsEarlier = processDateAtIndex(-2);
                if (pastMonthsNeeded > 2) {
                    activeDates.threeMonthsEarlier = processDateAtIndex(-3);
                }
            }
        }

        return activeDates;
    }

    getEventsOfActiveDate(date) {
        const monthYear1 = moment(date).format('DD-MM-YYYY');
        let eventsOfThisDate = [];
        if (this.state.eventsList && date) {
            this.state.eventsList?.map((item) => {
                monthYear1 === item?.eventOn ? eventsOfThisDate.push(item) : eventsOfThisDate = eventsOfThisDate;
            })
        }
        return eventsOfThisDate;
    }

    fetchEvntList2() {
        if (this.state.loadthired == false) {
            // let currentHeight =
            //    $("#one_14 #scrollableDivOneFourProfile")[0].scrollHeight -
            //    $("#one_14 #scrollableDivOneFourProfile").height();

            $('#one_14 .loaderclass').removeClass('removescrolling');
            const variable = localStorage.getItem('userid');
            const token = localStorage.getItem('token');
            this.setState({ hasMore: true, loaddocument: true, items: Array.from({ length: 10 }) })
            var time = Math.floor(new Date() / 1000);
            setTimeout(() => {
                $('#one_14 #scrollableDivOneFourProfile').addClass('scrollbarhiden');
                let scrollId = ""
                if (this.state.inverse == false) {
                    var serverpath = this.state.baseUrl + '/api/event/getEventsUserSpecific.php?userID=' + this.state.umid + '&date=' + this.state.evntData[this.state.evntData.length - 1].postDate + '&eventType=0&eventID=' + this.state.evntData[this.state.evntData.length - 1].eventID;
                    scrollId = this.state.evntData[this.state.evntData.length - 1].eventID;

                }
                if (this.state.inverse == true) {
                    var serverpath = this.state.baseUrl + '/api/event/getEventsUserSpecific.php?userID=' + this.state.umid + '&date=' + this.state.evntData[0].postDate + '&eventType=1&eventID=' + this.state.evntData[0].eventID;
                    scrollId = this.state.evntData[0].eventID;
                }
                fetch(serverpath, {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "TimeZone": moment.tz.guess(),
                        "Authorization": "Bearer " + token,
                    },
                })
                    .then((response) => response.json())
                    .then((responseJson) => {

                        if (responseJson.response.length < 20) {
                            if (this.state.inverse)
                                this.setState({ topcontat: 'on' });
                            else
                                this.setState({ contat: 'on' });
                        }
                        if (responseJson.response.length > 0) {
                            var datas = responseJson.response;
                            if (this.state.inverse == true) {

                                for (var i = datas.length - 1; i >= 0; i--) {
                                    this.state.evntData.unshift(datas[i]);
                                }
                            }
                            else {
                                for (var i = datas.length - 1; i >= 0; i--) {
                                    this.state.evntData.push(datas[i]);
                                }
                            }
                            if (this.state.inverse == false) {

                                $('#one_14 #scrollableDivOneFourProfile').addClass('scrollbarhiden');
                                setTimeout(() => {
                                    $('#one_14 #scrollableDivOneFourProfile').removeClass('scrollbarhiden');
                                }, 100)
                            }
                            if (this.state.inverse == true) {

                                $('#one_14 #scrollableDivOneFourProfile').addClass('scrollbarhiden');
                                setTimeout(() => {
                                    $('#one_14 #scrollableDivOneFourProfile').removeClass('scrollbarhiden');
                                }, 100)
                            }
                            // this.setState({ loaddocument: false }, () => {
                            if (this.state.evntData.length > 20) {
                                this.setState({ items: this.state.items.concat(Array.from({ length: 20 })) }, () => {
                                });
                            }
                            // setTimeout(() => {
                            this.setState({ loadthired: false });

                            if (this.state.inverse) {
                                const scrollSelector = `#hashu${scrollId}`
                                this.scrollTimelineToElementPosition(scrollSelector)
                            }

                            // }, 100);
                            // });
                        }
                        else {
                            $('#one_14 #scrollableDivOneFourProfile').addClass('scrollbarhiden');
                            setTimeout(() => {
                                $('#one_14 #scrollableDivOneFourProfile').removeClass('scrollbarhiden');
                            }, 100)
                        }
                        this.setState({ loaddocument: false });
                    });
            }, 100);
        }
    }

    onscrolldocument() {
        var pxcelate = $('#one_14 #scrollableDivOneFourProfile')[0].scrollHeight;
        var px = $("#one_14 #scrollableDivOneFourProfile").scrollTop();
        if (px < 50) {
            if (this.state.topcontat == "of") {
                this.setState({ inverse: true, loadthired: false }, () => {
                    if (px == 0) {
                        this.timeout = setTimeout(() => {
                            this.fetchEvntList2();
                        }, 100);
                        setTimeout(() => {
                            if (this.timeout) {
                                clearTimeout(this.timeout)
                                this.timeout = null
                            }
                        }, 100);
                    }
                });
                $('#one_14 .contantf').addClass('closeCd');
            }
            if (this.state.topcontat == "on") {
                $('#one_14 .contantf').removeClass('closeCd');
                // $('#one_14 .loaderclass').addClass('closeCd');
            }
            else {
                $('#one_14 .contantf').addClass('closeCd');
            }
        }
        else {
            if (this.state.contat == "of") {
                this.setState({ inverse: false, loadthired: false }, () => {

                    if (Math.round(px + $("#one_14 #scrollableDivOneFourProfile").height()) == pxcelate) {

                        // $('#one_14 .loaderclass').removeClass('topscrolls closeCd');
                        // $('#one_14 .loaderclass').addClass('bottomscrolls'); 
                        this.timeout = setTimeout(() => {
                            this.fetchEvntList2();
                        }, 100);
                        setTimeout(() => {
                            if (this.timeout) {
                                clearTimeout(this.timeout)
                                this.timeout = null
                            }
                        }, 100);
                    }
                });
            }
            if (this.state.contat == "on" && Math.round(px + $("#one_14 #scrollableDivOneFourProfile").height()) == pxcelate) {
                $('#one_14 .contantf').removeClass('closeCd');
                // $('#one_14 .loaderclass').addClass('closeCd');
            }
            else {
                $('#one_14 .contantf').addClass('closeCd');
            }
        }
    }

    updateActiveDatesOfTimetables(newActiveDate, action, calendar) {
        const getDate = (self, action) => {
            let date = this.state.timetableData[self][action + 'Date']
            if (date) {
                const formattedDate = moment(date, 'DD/MM/YYYY');
                const newDate = new Date(formattedDate);
                return newDate
            }
            else return date
        }

        let currentActiveDateNew = getDate('current', action)
        this.setActiveDatesOfTimetables(currentActiveDateNew)
    }

    getMonthYear(maxDate) {
        var mydate = maxDate;
        var min = moment(mydate, "DD/MM/YYYY");
        var minie = new Date(min);
        this.setActiveDatesOfTimetables(minie);
    }

    getDateFullName(date) {
        if (date) {
            const month = moment(date).format('MMMM');
            const year = moment(date).format('YYYY');
            return month + ' ' + year;
        }
    }

    godate() {
        $('#one_14 #listuser').removeClass('active');
        $('#one_14 #user').addClass('active');
        $('#one_14').removeClass('text-left profile-pag');
        $('#one_14').addClass('screen1');
        $('#one_14 #one2list').removeClass('active show');
        $('#one_14 #one2date').addClass('active show');
        this.setState({ isTimelineActive: false });
    }

    godocument() {
        $('#one_14 #user').removeClass('active');
        $('#one_14 #listuser').addClass('active');
        $('#one_14').removeClass('screen1');
        $('#one_14').addClass('text-left profile-pag');
        $('#one_14 #one2date').removeClass('active show');
        $('#one_14 #one2list').addClass('active show');
        this.setState({ fistname: 0, isTimelineActive: true });
        this.fetchEvntList();
    }

    componentWillUnmount() {
        document.title = "Timesort"
        this.updateSharedDataInContext();
    }

    setShouldUpdateOnSamePath = (val) => {
        this.setState({ shouldUpdateOnSamePath: val })
    }

    render() {

        $(".katexContentBlock .katex a").attr("target", "_blank");
        $(".katexContentBlock .katex a").attr("rel", "noopener noreferrer");
        if (this.state.cscreenname) {
            document.title = "Timesort \u2014          " + this.state.cscreenname;
        }

        return (
            <div className={`${this.props.pos} screen1 savedTimetables`} id="one_14">
                <div className="top newbg" id="myHeader">
                    <section className="header">
                        <div className="row">
                            <div className=" header_left pr-0">
                                <ul className="nav nav-tabs noteee">
                                    <li>
                                        <a className="link active" onClick={this.godate.bind(this)} id="user" data-toggle="tab" >
                                            <CalendarSvg />
                                        </a>
                                        <p onClick={this.godate.bind(this)} id="user" className='hoverIconInfo'>Timetables</p>
                                    </li>
                                    <li>
                                        <a data-toggle="tab" className="" onClick={this.godocument.bind(this)} id="listuser" >
                                            <TimelineSvg />
                                        </a>
                                        <p onClick={this.godocument.bind(this)} id="listuser" className='hoverIconInfo'>Bidirectionally-traversed timeline</p>
                                    </li>
                                </ul>
                            </div>
                            <div className=" monthsBlock">
                                <ul>
                                    {
                                        this.state.leftSideArray &&
                                        this.state.leftSideArray.map((item) =>
                                            <li>
                                                <a className={item.isActiveMonth ? "active" : ""} href="javascript:void(0)" onClick={() => this.getMonthYear(item.date)}>{item.short}</a>
                                                <p className='hoverDateInfo' onClick={() => this.getMonthYear(item.date)}>{this.getDateFullName(item.date)}</p>
                                            </li>
                                        )
                                    }
                                    {
                                        this.state.rightSideArray &&
                                        this.state.rightSideArray.map((item) =>
                                            <li>
                                                <a href="javascript:void(0)" onClick={() => this.getMonthYear(item.date)}>{item.short}</a>
                                                <p className='hoverDateInfo' onClick={() => this.getMonthYear(item.date)}>{this.getDateFullName(item.date)}</p>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                            <div className=" header_right pl-0">
                                <ul className="uld">
                                    <li className="more abort" ><Link to={"/settings"}> <DepartSvg /> <span>{"settings"}</span></Link></li>
                                </ul>
                            </div>
                        </div>
                    </section>

                </div>
                <div className="tab-content tab-content4">
                    {this.state.time == true ?
                        <>
                            {this.state.eventsList?.length ? (
                                <div id="one2date" className="tab-pane in active show">
                                    <div className="flex-center">
                                        {
                                            this.state.timetableData.current?.activeDate ? <BookmarkTimetable
                                                isScreenVisible={this.props.isScreenVisible}
                                                subspaceNumber={1}
                                                calendar='current'
                                                timetableData={this.state.timetableData.current}
                                                states={{
                                                    events: this.state.eventsList,
                                                    otherMinimum: this.state.other_minimum,
                                                    otherMaximum: this.state.other_maximum,
                                                    otherMonthYear: this.state.monthYearList,
                                                }}
                                                updateTimetableData={(data) => this.updateTimetableData(data, 'current')}
                                                updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'current')}
                                                getSavedTemporalInfo={(obj) => this.getSavedTemporalInfo(obj)}
                                            /> : ''
                                        }
                                        {
                                            this.state.timetableData.next?.activeDate ? <BookmarkTimetable
                                                isScreenVisible={this.props.isScreenVisible}
                                                subspaceNumber={1}
                                                calendar='next'
                                                timetableData={this.state.timetableData.next}
                                                states={{
                                                    events: this.state.eventsList,
                                                    otherMinimum: this.state.other_minimum,
                                                    otherMaximum: this.state.other_maximum,
                                                    otherMonthYear: this.state.monthYearList,
                                                }}
                                                updateTimetableData={(data) => this.updateTimetableData(data, 'next')}
                                                updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'next')}
                                                getSavedTemporalInfo={(obj) => this.getSavedTemporalInfo(obj)}
                                            /> : ''
                                        }
                                        {
                                            this.state.timetableData.twoMonthsAhead?.activeDate ? <BookmarkTimetable
                                                isScreenVisible={this.props.isScreenVisible}
                                                subspaceNumber={1}
                                                calendar='twoMonthsAhead'
                                                timetableData={this.state.timetableData.twoMonthsAhead}
                                                states={{
                                                    events: this.state.eventsList,
                                                    otherMinimum: this.state.other_minimum,
                                                    otherMaximum: this.state.other_maximum,
                                                    otherMonthYear: this.state.monthYearList,
                                                }}
                                                updateTimetableData={(data) => this.updateTimetableData(data, 'twoMonthsAhead')}
                                                updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'twoMonthsAhead')}
                                                getSavedTemporalInfo={(obj) => this.getSavedTemporalInfo(obj)}
                                            /> : ''
                                        }
                                        {
                                            this.state.timetableData.threeMonthsAhead?.activeDate ? <BookmarkTimetable
                                                isScreenVisible={this.props.isScreenVisible}
                                                subspaceNumber={1}
                                                calendar='threeMonthsAhead'
                                                timetableData={this.state.timetableData.threeMonthsAhead}
                                                states={{
                                                    events: this.state.eventsList,
                                                    otherMinimum: this.state.other_minimum,
                                                    otherMaximum: this.state.other_maximum,
                                                    otherMonthYear: this.state.monthYearList,
                                                }}
                                                updateTimetableData={(data) => this.updateTimetableData(data, 'threeMonthsAhead')}
                                                updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'threeMonthsAhead')}
                                                getSavedTemporalInfo={(obj) => this.getSavedTemporalInfo(obj)}
                                            /> : ''
                                        }
                                        {
                                            this.state.timetableData.previous?.activeDate ? <BookmarkTimetable
                                                isScreenVisible={this.props.isScreenVisible}
                                                subspaceNumber={1}
                                                calendar='previous'
                                                timetableData={this.state.timetableData.previous}
                                                states={{
                                                    events: this.state.eventsList,
                                                    otherMinimum: this.state.other_minimum,
                                                    otherMaximum: this.state.other_maximum,
                                                    otherMonthYear: this.state.monthYearList,
                                                }}
                                                updateTimetableData={(data) => this.updateTimetableData(data, 'previous')}
                                                updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'previous')}
                                                getSavedTemporalInfo={(obj) => this.getSavedTemporalInfo(obj)}
                                            /> : ''
                                        }
                                        {
                                            this.state.timetableData.twoMonthsEarlier?.activeDate ? <BookmarkTimetable
                                                isScreenVisible={this.props.isScreenVisible}
                                                subspaceNumber={1}
                                                calendar='twoMonthsEarlier'
                                                timetableData={this.state.timetableData.twoMonthsEarlier}
                                                states={{
                                                    events: this.state.eventsList,
                                                    otherMinimum: this.state.other_minimum,
                                                    otherMaximum: this.state.other_maximum,
                                                    otherMonthYear: this.state.monthYearList,
                                                }}
                                                updateTimetableData={(data) => this.updateTimetableData(data, 'twoMonthsEarlier')}
                                                updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'twoMonthsEarlier')}
                                                getSavedTemporalInfo={(obj) => this.getSavedTemporalInfo(obj)}
                                            /> : ''
                                        }
                                        {
                                            this.state.timetableData.threeMonthsEarlier?.activeDate ? <BookmarkTimetable
                                                isScreenVisible={this.props.isScreenVisible}
                                                subspaceNumber={1}
                                                calendar='threeMonthsEarlier'
                                                timetableData={this.state.timetableData.threeMonthsEarlier}
                                                states={{
                                                    events: this.state.eventsList,
                                                    otherMinimum: this.state.other_minimum,
                                                    otherMaximum: this.state.other_maximum,
                                                    otherMonthYear: this.state.monthYearList,
                                                }}
                                                updateTimetableData={(data) => this.updateTimetableData(data, 'threeMonthsEarlier')}
                                                updateActiveDatesOfTimetable={(date, action) => this.updateActiveDatesOfTimetables(date, action, 'threeMonthsEarlier')}
                                                getSavedTemporalInfo={(obj) => this.getSavedTemporalInfo(obj)}
                                            /> : ''
                                        }
                                    </div>
                                    <div id="five1" class="likeaddto"><img class="w-100" src="../../images/More.png" alt="image" /></div>
                                    <div class="likeaddtop" id="four1"><img class="w-100" src="../../images/Erase.png" alt="image" /></div>
                                </div>
                            ) : (
                                !this.state.isTimelineActive && (
                                    <div className="notfound">
                                        <p>&ndash;</p>
                                    </div>
                                )
                            )}

                            <div id="one2list" className="tab-pane fade">
                                <div class={this.state.evntData.length > 0 ? "loaderclass closeCdff h" : "loaderclass onscroll"}><img src="../../images/newgif.gif" alt="loading" /></div>
                                {/* <div class={this.state.contat == "of" ? "loaderclass contantf closeCd h" : "loaderclass contantf"}>No More post Available</div> */}
                                <div className={"sectionone changable" + ((!this.state.cbio || !this.state.cwebsite) ? " emptyBioWebsite" : "")}>
                                    <div
                                        id="scrollableDivOneFourProfile"
                                        className={this.state.evntData.length > 0 ? this.state.loaddocument == true ? "scrollClass newscreen1 " : "scrollClass newscreen1" : "scrollClass newscreen1"}
                                        style={{
                                            overflow: 'auto',
                                            display: 'flex !important',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <InfiniteScroll
                                            className={this.state.evntData.length > 0 ? "topbuttom_scroll" : ""}
                                            dataLength={this.state.evntData.length}
                                            style={{ display: 'flex', flexDirection: 'row' }} //To put endMessage and loader to the top.
                                            hasMore={true}
                                            onScroll={this.onscrolldocument.bind(this)}

                                            loader={
                                                <div className={`loaderclass ${this.state.inverse ? "topscrolls" : "bottomscrolls"} ${this.state.loaddocument ? "" : "closeCd"}`}>
                                                    <img
                                                        src="../../images/newgif.gif"
                                                        alt="loading"
                                                    />


                                                </div>}
                                            scrollableTarget="scrollableDivOneFourProfile"
                                        >
                                            <div className="mb-2 timeline-wrapper">
                                                {this.state.evntData.map((j, i) => {
                                                    return (
                                                        <div className="timeline-event-box">
                                                            <span id={'hashu' + j.eventID} className={j.eventID}></span>
                                                            <div id={"crox" + j.eventID} className="postlike">
                                                                <div className="userin rmrf">
                                                                    <h1 className='new-heading-tex-780' dangerouslySetInnerHTML={{ __html: formatDate(getdateformat(j.postedOn, j.postedOn2, j.timezone)) }}></h1>
                                                                    <h5>
                                                                        {j?.link ? (
                                                                            <a href={modifyHref(j?.link)} target='_blank' className='userLink'>
                                                                                <div dangerouslySetInnerHTML={{ __html: fetchTeXCompiledCaption(j.status) }} />
                                                                            </a>
                                                                        ) : (
                                                                            <div dangerouslySetInnerHTML={{ __html: fetchTeXCompiledCaption(j.status) }} />
                                                                        )}
                                                                    </h5>
                                                                </div>
                                                                <div className="post ">
                                                                    {(() => {
                                                                        if (j.mediaType == "video") {
                                                                            return (
                                                                                <div id={'Video' + j.eventID} class="video-div">
                                                                                    <Player
                                                                                        ref={player => {
                                                                                            this[`ref${i}`] = player;
                                                                                        }}
                                                                                        autoPlay={false} className="video-react-has-started">
                                                                                        <source src={j.eventImage.startsWith("http") ? j.eventImage : process.env.REACT_APP_BASE_URL + j.eventImage} type="video/mp4" />
                                                                                        <ControlBar autoHide={true} className="my-class" />
                                                                                    </Player>
                                                                                    <ul class="videoulkap pb-3">
                                                                                        <li><Button data-id={"player" + j.id} className={-10} onClick={() => this.changeCurrentTimeNew(-10, i)} ><img class="img1" src="../../images/Backwards.png" />
                                                                                            <span>10</span></Button>
                                                                                        </li>
                                                                                        <li><img class="img2" src="../../images/pause.png" /></li>
                                                                                        <li><Button data-id={j.id} className={10} onClick={() => this.changeCurrentTimeNew(10, i)} ><img class="img3" src="../../images/Forwards.png" />
                                                                                            <span>10</span></Button>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>);
                                                                        }
                                                                        else if (j.mediaType == "image") {
                                                                            return (
                                                                                <img className="w-100 mt-1" src={this.state.baseUrl + j.eventImage} alt="image" />
                                                                            );
                                                                        }
                                                                    }
                                                                    )()}
                                                                    <div className="hearti">
                                                                        <img className="w-100" src="../../images/cklick.png" alt="image" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ul className={j.mediaType == null ? "videoul mt3 status" : "videoul mt3"} >
                                                                <li>
                                                                </li>

                                                                {(() => {
                                                                    if (j.withwho != null) {
                                                                        return (<li><img src="../../images/b.png" alt="icon" />
                                                                            {(() => {
                                                                                if (j.withwho != null) {
                                                                                    return (
                                                                                        j.withwho.map((k, l) => {
                                                                                            return (
                                                                                                <span><a href="#"><Link to={{
                                                                                                    pathname: `/${k.username}`,
                                                                                                    state: { id: k.user_id }
                                                                                                }}
                                                                                                >{k.screenName}</Link></a><span>, </span></span>
                                                                                            );
                                                                                        })
                                                                                    )
                                                                                }
                                                                                else {
                                                                                    return (
                                                                                        <a href="#"></a>
                                                                                    )
                                                                                }
                                                                            }
                                                                            )()}
                                                                        </li>
                                                                        )
                                                                    }
                                                                }
                                                                )()}
                                                                {(() => {
                                                                    if (j.likedByUsers.length > 0) {
                                                                        return (<li>
                                                                            <img src="../../images/c.png" alt="icon" />
                                                                            {(() => {
                                                                                if (j.likedByUsers.length > 0) {
                                                                                    return (
                                                                                        j.likedByUsers.map((q, t) => {
                                                                                            return (<span><a href="#"><Link to={{
                                                                                                pathname: `/${q.username}`,
                                                                                                state: { id: q.user_id }
                                                                                            }}
                                                                                            >{q.screenName}</Link></a><span>, </span></span>
                                                                                            );
                                                                                        })
                                                                                    )
                                                                                }
                                                                            }
                                                                            )()}
                                                                        </li>
                                                                        )
                                                                    }
                                                                }
                                                                )()}
                                                                {(() => {
                                                                    if (j.likedByUsers.length > 0) {
                                                                        return (<li>
                                                                            <img src="../../images/d.png" alt="icon" />
                                                                            {(() => {
                                                                                if (j.commentByUsers.length > 0) {
                                                                                    return (
                                                                                        j.commentByUsers.map((m, index) => {
                                                                                            return (
                                                                                                <span><a href="#"><Link to={{
                                                                                                    pathname: `/${m.username}`,
                                                                                                    state: { id: m.user_id }
                                                                                                }}
                                                                                                >{m.screenName}</Link></a><span>, </span></span>
                                                                                            );
                                                                                        })
                                                                                    )
                                                                                }
                                                                            }
                                                                            )()}
                                                                        </li>
                                                                        )
                                                                    }
                                                                }
                                                                )()}
                                                            </ul>
                                                        </div>)

                                                }
                                                )
                                                }</div>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </div></>
                        : <div className="loaderclass">
                            <img
                                src="../../images/newgif.gif"
                                alt="loading"
                            />
                        </div>

                    }
                </div>
                {localStorage.getItem('token')
                    ? <Footer {...this.props} ref={this.child} {...this.footerPropsForUpdateOnSamePath} updateClickedButtonToExit={this.updateClickedButtonToExit.bind(this)} />
                    : <GuestFooter {...this.props} ref={this.child} {...this.footerPropsForUpdateOnSamePath} updateClickedButtonToExit={this.updateClickedButtonToExit.bind(this)} />
                }
            </div>
        );
    };
};

export default SavedTemporalInfo;