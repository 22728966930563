import moment from "moment";

/**
 * Given a date and an event list, return an array of events that occurred within the same month as the given date.
 * @param {Date} date The date to compare with.
 * @param {Array} eventList The list of events.
 * @returns {Array} An array of events that occurred in the same month as the given date.
 */
export const getActiveDatesOfTheMonth = (date, eventList) => {
    const monthYear1 = moment(date).format('MMMM YYYY');
    let datesOfThisMonth = [];
    if (eventList) {
        eventList?.forEach((item,index) => {
            const formattedDate = moment(item?.eventOn, "DD-MM-YYYY").format("MMMM YYYY");
            if (monthYear1 === formattedDate) {
                item.tile_type = index % 2 == 0 ? "highlight" : "darkblue"
                datesOfThisMonth.push(item)
            }
            else datesOfThisMonth = datesOfThisMonth;
        })
    }
    return datesOfThisMonth;
}

/**
 * Given an array of events, return an array of events where each event's date is unique.
 * If there are multiple events on the same date, only the first event is included in the output.
 * @param {Array} events The array of events.
 * @returns {Array} An array of events with unique dates.
 */
export const getUniqueDatesOfTheMonth = (events) => {
    const uniqueEvents = [];
    const seenDates = new Set();

    events.forEach((event) => {
        const eventDate = event.eventOn;
        if (!seenDates.has(eventDate)) {
            seenDates.add(eventDate);
            uniqueEvents.push(event);
        }
    });

    return uniqueEvents;
};

/**
 * Return the active date of the month based on important events.
 * The logic is to choose the date which has more important events.
 * If there's a tie, the first date is chosen.
 * 
 * @param {Date} date - the date to check
 * @param {object[]} availableEvents - list of events on the date
 * @returns {object} - the active date object
 */
export const getActiveDateBasedOnImportantEvent = (date, availableEvents) => {
    if (!date) return undefined;

    if (!availableEvents?.length) return undefined;

    const impCount = availableEvents.reduce((count, el) =>
        count + (el.importantEvents ? 1 : 0), 0);

    const hasMoreImportantEvents = impCount > (availableEvents.length - impCount);
    return availableEvents.find(el =>
        el.importantEvents === (hasMoreImportantEvents ? 1 : 0));
};

export const determineDateToBeSelectForMonths = (date, availableEvents) => {
    if(!date) return undefined;

    const currentDate = moment();
    const givenDate = moment(date);
    let activeDate = date;

    if (givenDate.isSame(currentDate, 'month')) {
        const today = currentDate.startOf('day');
        let closestEvent = null;
        let smallestDiff = Infinity;

        availableEvents.forEach((event) => {
            const eventDate = moment(event.eventOn, "DD-MM-YYYY").startOf('day');
            const diffToToday = Math.abs(eventDate.diff(today, 'days'));

            if (diffToToday < smallestDiff) {
                smallestDiff = diffToToday;
                closestEvent = event;
            }
        });
        activeDate = closestEvent ? moment(closestEvent.eventOn, "DD-MM-YYYY").toDate() : null;
    } else if (givenDate.isBefore(currentDate, 'month')) {
        let eventToBeSelect = null;
        let highlightedTiles = availableEvents.filter(event => event.tile_type === "highlight")?.length;
        let darkblueTiles = availableEvents.length - highlightedTiles;

        if (highlightedTiles > darkblueTiles) {
            eventToBeSelect = availableEvents.find(event => event.tile_type === "highlight");
        } else if (highlightedTiles < darkblueTiles) {
            eventToBeSelect = availableEvents.find(event => event.tile_type === "darkblue");
        } else {
            const highlightEvents = availableEvents.filter(event => event.tile_type === "highlight");
            eventToBeSelect = highlightEvents[Math.floor(Math.random() * highlightEvents.length)];
        }

        activeDate = moment(eventToBeSelect?.eventOn, "DD-MM-YYYY").toDate();
    } else if (givenDate.isAfter(currentDate, 'month')) {
        const firstEventOfMonth = availableEvents.sort((a, b) => {
            const dateA = moment(a.eventOn, "DD-MM-YYYY");
            const dateB = moment(b.eventOn, "DD-MM-YYYY");
            return dateA - dateB;
        })[0];
        activeDate = moment(firstEventOfMonth?.eventOn, "DD-MM-YYYY").toDate();
    }
    return activeDate
}