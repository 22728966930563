import SecondTab from "../../components/SecondTab";
import SinglePage from "../../components/SinglePage";
import Users from "../../components/Users";
import CommentOn from "../../components/CommentOn";
import Likes from "../../components/Likes";
import Report from "../../components/Report";
import Followers from "../../components/Followers";
import Following from "../../components/Following";
import Mutual from "../../components/Mutual";
import Myprofile from "../../components/Myprofile";
import Myfollowers from "../../components/Myfollower";
import Myfollowing from "../../components/Myfollowing";
import SecondTabs from "../../components/SecondTabs";
import AddPost from "../../components/AddPost";
import Notifications from "../../components/Notifications";
import EditPost from "../../components/EditPost";
import EditProfile from "../../components/EditProfile";
import Chatusers from "../../components/Chatusers";
import Chat from "../../components/Chat";
import Tag from "../../components/tag";
import Aboutus from "../../components/Aboutus";
import Privacy from "../../components/Privacy";
import Terms from "../../components/Terms";
import Settings from "../../components/Settings";
import Login from "../../components/Login";
import { pageState } from "./pageState";
import SavedTemporalInfo from "../../components/SavedTemporalInfo/SavedTemporalInfo";

export const routes = [
    {
      comp: (props) => <SecondTab {...props} />,
      id: "one_3",
      state: pageState.terminate,
      path: "/",
      exactMatch: true,
      loginRequired: true,
    },
    {
      comp: (props) => <SecondTabs {...props} />,
      id: "one_3",
      state: pageState.terminate,
      path: "/",
      exactMatch: true,
      loginRequired: false,
    },
    {
      comp: (props) => <AddPost {...props} />,
      id: "one_10",
      state: pageState.terminate,
      path: "/upload",
      exactMatch: true,
      loginRequired: true,
    },
    {
      comp: (props) => <EditPost {...props} />,
      id: "one_16",
      state: pageState.terminate,
      path: "/edit/",
      exactMatch: false,
      loginRequired: false,
    },
    {
      comp: (props) => <SinglePage {...props} />,
      id: "one_15",
      state: pageState.terminate,
      path: "/SinglePage/",
      exactMatch: false,
      loginRequired: false,
    },
    {
      comp: (props) => <Chat {...props} />,
      id: "one_89",
      state: pageState.terminate,
      path: "/Chat/",
      exactMatch: false,
      loginRequired: false,
    },
    {
      comp: (props) => <Tag {...props} />,
      id: "one_13",
      state: pageState.terminate,
      path: "/tag/",
      exactMatch: false,
      loginRequired: false,
    },
    {
      comp: (props) => <CommentOn {...props} />,
      id: "one_22",
      state: pageState.terminate,
      path: "/CommentOn/",
      exactMatch: false,
      loginRequired: false,
    },
    {
      comp: (props) => <Likes {...props} />,
      id: "one_8",
      state: pageState.terminate,
      path: "/Likes/",
      exactMatch: false,
      loginRequired: false,
    },
    {
      comp: (props) => <Login showSignUp={true} {...props} />,
      id: "loginSignup",
      state: pageState.terminate,
      path: "/sign-up",
      exactMatch: true,
      loginRequired: false,
    },
    {
      comp: (props) => <Login showSignUp={false} {...props}  />,
      id: "loginSignup",
      state: pageState.terminate,
      path: "/login",
      exactMatch: true,
      loginRequired: false,
    },
    {
      comp: (props) => <Chatusers {...props} />,
      id: "onetest_7",
      state: pageState.terminate,
      path: "/Chatusers",
      exactMatch: true,
      loginRequired: false,
    },
    {
      comp: (props) => <EditProfile {...props} />,
      id: "one_17",
      state: pageState.terminate,
      path: "/EditProfile",
      exactMatch: true,
      loginRequired: false,
    },
    {
      comp: (props) => <Myprofile {...props} />,
      id: "one_2",
      state: pageState.terminate,
      path: "/self",
      exactMatch: true,
      loginRequired: false,
    },
    {
      comp: (props) => <Myfollowers {...props} />,
      id: "onetest_8",
      state: pageState.terminate,
      path: "/Myfollower",
      exactMatch: true,
      loginRequired: false,
    },
    {
      comp: (props) => <Settings {...props} />,
      id: "one_12",
      state: pageState.terminate,
      path: "/settings",
      exactMatch: true,
      loginRequired: false,
    },
    {
      comp: (props) => <SavedTemporalInfo {...props} />,
      id: "one_14",
      state: pageState.terminate,
      path: "/saved",
      exactMatch: true,
      loginRequired: false,
    },
    {
      comp: (props) => <Aboutus {...props} />,
      id: "oneabout",
      state: pageState.terminate,
      path: "/Aboutus",
      exactMatch: true,
      loginRequired: false,
    },
    {
      comp: (props) => <Privacy {...props} />,
      id: "onePrivacy",
      state: pageState.terminate,
      path: "/Privacy",
      exactMatch: true,
      loginRequired: false,
    },
    {
      comp: (props) => <Terms {...props} />,
      id: "oneterms",
      state: pageState.terminate,
      path: "/Terms",
      exactMatch: true,
      loginRequired: false,
    },
    {
      comp: (props) => <Notifications {...props} />,
      id: "one_5",
      state: pageState.terminate,
      path: "/Notifications",
      exactMatch: true,
      loginRequired: false,
    },
    {
      comp: (props) => <Myfollowing {...props} />,
      id: "onetest_7",
      state: pageState.terminate,
      path: "/Myfollowing",
      exactMatch: true,
      loginRequired: false,
    },
    {
      comp: (props) => <Followers {...props} />,
      id: "one_8",
      state: pageState.terminate,
      path: "/Followers/",
      exactMatch: false,
      loginRequired: false,
    },
    {
      comp: (props) => <Following {...props} />,
      id: "onetest_7",
      state: pageState.terminate,
      path: "/Following/",
      exactMatch: false,
      loginRequired: false,
    },
    {
      comp: (props) => <Mutual {...props} />,
      id: "one_9",
      state: pageState.terminate,
      path: "/Mutual/",
      exactMatch: false,
      loginRequired: false,
    },
    {
      comp: (props) => <Report {...props} />,
      id: "one_18",
      state: pageState.terminate,
      path: "/Report/",
      exactMatch: false,
      loginRequired: false,
    },
    {
      comp: (props) => <Users {...props} />,
      id: "one_4",
      state: pageState.terminate,
      path: "/",
      exactMatch: false,
      loginRequired: false,
    },
    {
      comp: (props) => <Users {...props} />,
      id: "one_4",
      state: pageState.terminate,
      path: "/",
      exactMatch: false,
      loginRequired: false,
    },
  ];
  