import { findUser } from "../../Services/api";
import { removeDuplicateObjectsFromArray } from "../arrayHelper";

export async function searchProfile(input, existingProfiles, pageNo) {
    if (!input)
        return []

    const numberOfProfilesToSend = 60
    let loadedProfiles = JSON.parse(localStorage.getItem("entry"))
    let foundProfilesInExistingProfiles = searchProfileWithin(input, loadedProfiles);

    if (foundProfilesInExistingProfiles.length >= numberOfProfilesToSend)
        return foundProfilesInExistingProfiles.slice(0, numberOfProfilesToSend)

    // let mostVisitedProfiles = localStorage.getItem("mostVisitedProfiles")
    // let profiles = []

    // if (mostVisitedProfiles)
    //     profiles = JSON.parse(mostVisitedProfiles)

    let profiles = await findUser(input, pageNo, 0, numberOfProfilesToSend)
    return removeDuplicateObjectsFromArray([...foundProfilesInExistingProfiles, ...profiles]).slice(0, numberOfProfilesToSend)
}

/**
 * 
 * @param {string} search 
 * @param {[Object]} listOfProfiles 
 */
function searchProfileWithin(search, listOfProfiles) {
    let loggedInUserId = localStorage.getItem("userid");

    // Split the search string into individual keywords, and remove any leading/trailing whitespace
    let keywords = search.trim().toLowerCase().split(/\s+/);

    // Create a regular expression to match the keywords in the correct order, but with any characters in between
    let regex = new RegExp(keywords.join('.*'), 'i');  // 'i' for case-insensitive matching

    return listOfProfiles.filter(user => {
        // Check if the regex matches the username or screenName and user_id is not the logged-in user's id
        let matchesKeywords = regex.test(user.username.toLowerCase()) || regex.test(user.screenName.toLowerCase());
        
        return matchesKeywords && user.user_id !== loggedInUserId;
    });
}
